import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import { StyleSheet, View, Text, ActivityIndicator, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { FontAwesome } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc } from 'firebase/firestore';
import constants from '../../utils/constants';

const ContactInfo = () => {
    const [isChamberFocus, setIsChamberFocus] = useState(false);
    const [chamberValue, setChamberValue] = useState(null);
    const [committeeValue, setCommitteeValue] = useState(null);
    const [committeeOptions, setCommitteeOptions] = useState([]);
    const [committeeData, setCommitteeData] = useState(null);
    const [loading, setLoading] = useState(false);
    const headerHeight = useHeaderHeight();

    const chamberOptions = [
        { label: 'House', value: 'houseCommittees' },
        { label: 'Senate', value: 'senateCommittees' },
    ];

    const fetchCommittees = async (chamber) => {
        setLoading(true);
        const docRef = doc(db, 'contacts', chamber);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const committees = data.committees.map((committee, index) => ({
                label: committee.committee,
                value: index,
                rep: committee.rep,
                phone: committee.phone,
                email: committee.email,
                notes: committee.notes
            }));
            setCommitteeOptions(committees);
        } else {
            console.log("No such document!");
        }
        setLoading(false);
    };

    const handleChamberChange = (item) => {
        setChamberValue(item.value);
        setCommitteeValue(null);
        setCommitteeData(null);
        fetchCommittees(item.value);
    };

    const handleCommitteeChange = (item) => {
        setCommitteeValue(item.value);
        const selectedCommittee = committeeOptions.find(committee => committee.value === item.value);
        setCommitteeData(selectedCommittee);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    };

    const cleanPhoneNumber = (phoneNumber) => {
        return ('' + phoneNumber).replace(/\D/g, '');
    };

    const handlePhonePress = (phoneNumber) => {
        Linking.openURL(`tel:+1${cleanPhoneNumber(phoneNumber)}`).catch(err => console.error('Error opening phone client:', err));
    };

    const handleEmailPress = (email) => {
        Linking.openURL(`mailto:${email}`).catch(err => console.error('Error opening mail client:', err));
    };

    return (
        <ScrollView style={{ backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { paddingTop: headerHeight }]}>
                <View style={styles.dropdownContainer}>
                    <Dropdown
                        style={[styles.dropdown, isChamberFocus && { borderColor: 'blue' }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9 }}
                        iconStyle={styles.iconStyle}
                        data={chamberOptions}
                        renderLeftIcon={() => (
                            <FontAwesome style={styles.icon} name="building-o" size={18} color={constants.ICON_COLOR} />
                        )}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isChamberFocus ? 'Select Chamber' : '...'}
                        searchPlaceholder="Search..."
                        value={chamberValue}
                        onFocus={() => setIsChamberFocus(true)}
                        onBlur={() => setIsChamberFocus(false)}
                        onChange={handleChamberChange}
                    />
                </View>

                {loading ? (
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                ) : (
                    chamberValue && (
                        <View style={styles.dropdownContainer}>
                            <Dropdown
                                style={[styles.dropdown, { height: 40 }]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                                itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                                itemTextStyle={{ color: 'white' }}
                                activeColor='#3f3f3f'
                                inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                                search
                                searchPlaceholder="Search..."
                                iconStyle={styles.iconStyle}
                                data={committeeOptions}
                                renderLeftIcon={() => (
                                    <FontAwesome style={styles.icon} name="users" size={18} color={constants.ICON_COLOR} />
                                )}
                                maxHeight={800}
                                labelField="label"
                                valueField="value"
                                placeholder='Select Committee'
                                value={committeeValue}
                                onChange={handleCommitteeChange}
                            />
                        </View>
                    )
                )}

                {committeeData && (
                    <View style={styles.committeeDetailsContainer}>
                        <Text style={styles.committeeDetailLabel}>Representative:</Text>
                        <Text style={styles.committeeDetailValue}>{committeeData.rep ? committeeData.rep : "N/A"}</Text>

                        <Text style={styles.committeeDetailLabel}>Phone:</Text>
                        <TouchableOpacity onPress={() => handlePhonePress(committeeData.phone)}>
                            {committeeData.phone ?
                                <Text style={[styles.committeeDetailValue, { textDecorationLine: 'underline' }]}>
                                    {formatPhoneNumber(committeeData.phone)}
                                </Text>
                                :
                                <Text style={[styles.committeeDetailValue]}>{"N/A"}</Text>
                            }
                        </TouchableOpacity>

                        <Text style={styles.committeeDetailLabel}>Email:</Text>
                        <TouchableOpacity onPress={() => handleEmailPress(committeeData.email)}>
                            {committeeData.email ?
                                <Text style={[styles.committeeDetailValue, { textDecorationLine: 'underline' }]}>{committeeData.email}</Text>
                                :
                                <Text style={[styles.committeeDetailValue]}>{"N/A"}</Text>
                            }
                        </TouchableOpacity>

                        <Text style={styles.committeeDetailLabel}>Additional Committees/Former Admin:</Text>
                        <Text style={styles.committeeDetailValue}>{committeeData.notes ? committeeData.notes : "N/A"}</Text>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: constants.PRIMARY_DARK,
        alignItems: 'center',
        padding: 10,
    },
    dropdownContainer: {
        width: '100%',
        paddingHorizontal: 16,
        marginBottom: 10,
        alignItems: 'center',
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        width: '90%',
        borderColor: 'gray',
        borderWidth: 1.5,
        borderRadius: 9,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
        color: '#e5e5e5',
    },
    selectedTextStyle: {
        fontSize: 16,
        color: '#e5e5e5',
    },
    icon: {
        marginRight: 10,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    textStyle: {
        color: 'white',
        fontSize: 16,
        marginBottom: 5,
    },
    committeeDetailsContainer: {
        width: '88%',
        backgroundColor: '#3f3f3f',
        padding: 10,
        borderRadius: 9,
        marginTop: 20,
    },
    committeeDetailLabel: {
        color: 'white',
        fontSize: 17,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    committeeDetailValue: {
        color: 'white',
        fontSize: 17,
        marginBottom: 30,
    },
});

export default ContactInfo;