import React, { useEffect, useState } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, ScrollView, Button, Alert, Platform } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";

const AIAnalysis = ({ route, navigation }) => {
  const { billData, title, email } = route.params
  const [currentIndex, setCurrentIndex] = useState(null);
  const [userData, setUserData] = useState()
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    async function getUserData() {
      const docRef = doc(db, "users", email);
      await getDoc(docRef)
        .then(doc => doc.data())
        .then(data => setUserData(data))
    }
    getUserData()
  }, [])

  const data = [
    {
      question: "Bill's intent",
      answer: billData.analysis?.intent,
    },
    {
      question: 'Why would Republicans disagree with this bill?',
      answer: billData.analysis?.rDisagree,
    },
    {
      question: 'Why would Democrats disagree with the bill?',
      answer: billData.analysis?.dDisagree,
    },
    {
      question: 'Wording that might be hard to understand for an average person?',
      answer: billData.analysis?.wording,
    },
    {
      question: 'Anything that might seem deceiving to an average person?',
      answer: billData.analysis?.deceiving,
    },
    {
      question: 'Republican questions for their senators/representatives',
      answer: billData.analysis?.rQuestions,
    },
    {
      question: 'Democrat questions for their senators/representatives',
      answer: billData.analysis?.dQuestions,
    },
  ];

  const addToNotes = async (question, answer) => {
    const legNotes = userData.legislationNotes || []
    const currentNotes = legNotes?.findIndex(item => item.id === billData.id);
    if (currentNotes !== -1) {
      legNotes[currentNotes].notes = legNotes[currentNotes].notes + `\n\n${question}\n${answer}`
    } else {
      legNotes.push({
        id: billData.id,
        notes: `\n\n${question}\n${answer}`,
      })
    }

    const userRef = doc(db, "users", email);
    await updateDoc(userRef, {
      legislationNotes: legNotes
    });
    Alert.alert(
      "Saved",
      "Added to Legislation Notes!"
    );
  }

  return (
    <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
      <View style={[styles.container, { marginTop: headerHeight + 10 }]} >
        <ScrollView style={styles.scrollView}>
          {data.map(({ question, answer }, index) => {
            return (
              <View key={question}>
                <Pressable
                  onPress={() => {
                    setCurrentIndex(index === currentIndex ? null : index);
                  }}
                  style={[styles.card, styles.buttonClose]}
                  activeOpacity={0.9}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <MaterialIcons name="notes" size={24} color={constants.ICON_COLOR} />
                    <View style={{ marginLeft: 10, width: '80%' }}>
                      <Text style={[styles.textStyle]}>{question}</Text>
                    </View>
                    {index === currentIndex ?
                      <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                      :
                      <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                      // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                    }
                  </View>
                  {index === currentIndex && (
                    <View>

                      <Pressable
                        onPress={() => addToNotes(question, answer)}
                      >
                        <View style={{ flexDirection: 'row', marginBottom: 10, justifyContent: 'center' }}>
                          <AntDesign name="pluscircle" size={17} color={constants.ICON_COLOR} style={{ marginRight: 5 }} />
                          <Text style={[styles.textStyle, { alignSelf: 'center', color: constants.ICON_COLOR }]}>Add to Legislation Notes</Text>
                        </View>
                      </Pressable>
                      <Text style={[styles.subTextStyle]}>
                        {answer}
                      </Text>
                    </View>
                  )}
                </Pressable>
              </View>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
}

export default AIAnalysis

const styles = StyleSheet.create({
  container: {
    backgroundColor: constants.PRIMARY_DARK,
    // marginTop: 120,
    flex: 1,
    margin: 12,
  },
  cardContainer: {
    flexGrow: 1,
  },
  card: {
    borderRadius: 9,
    borderWidth: 3,
    borderColor: 'black',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 12,
    elevation: 2,
    // alignSelf: 'center',
    // width: "98%",
    flexGrow: 1,
    marginBottom: 1,
    // borderBottomWidth: .5,
    // borderColor: 'gray',
  },
  button: {
    flexDirection: 'row',
    width: "98%",
    height: 85,
    borderBottomColor: 'gray',
    borderBottomWidth: .2,
    // marginBottom: 35,
    textAlignVertical: 'center',
    alignItems: 'center',
    paddingTop: 20
  },
  trackedButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonOpen: {
    backgroundColor: "white",
  },
  buttonClose: {
    backgroundColor: constants.SECONDARY_DARK,
  },
  textStyle: {
    color: constants.TITLE_COLOR,
    opacity: .9,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: 'Verdana',
    // alignSelf: "center",
    // ...Platform.select({
    //     ios: {
    //         lineHeight: 20
    //     },
    //     android: {}
    // })
  },
  subTextStyle: {
    flexGrow: 0,
    color: constants.SUBTEXT_COLOR,
    width: "90%",
    // height: 20,
    marginLeft: 18,
    fontSize: 14,
    // fontWeight: "bold",
    // textAlignVertical: "center",
    fontFamily: 'Verdana',
    ...Platform.select({
      ios: {
        lineHeight: 20
      },
      android: {}
    })
  },
  heading: {
    fontSize: 20,
    fontFamily: 'Verdana',
    color: constants.TITLE_COLOR,
    fontWeight: '600',
    marginBottom: 15,
  },
  body: {
    fontSize: 16,
    fontFamily: 'Verdana',
    color: constants.TITLE_COLOR,
    marginBottom: 15,
  },
  answerList: {
    marginTop: 10,
  },
});