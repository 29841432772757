import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, Button, Alert, TextInput, Pressable, Platform } from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";

const KeywordTracking = ({ route }) => {
    const { email } = route.params;
    const [trackedData, setTrackedData] = useState();
    const [originalTrackedData, setOriginalTrackedData] = useState();
    const [keyword, setKeyword] = useState();
    const [duplicateWarning, setDuplicateWarning] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();


    useEffect(() => {
        async function getTrackedWords() {
            const docRef = doc(db, 'users', email);
            await getDoc(docRef)
                .then(doc => doc.data())
                .then(data => {
                    const trackedArr = [];
                    for (let i = 0; i < data.trackedWords.length; i += 1) {
                        trackedArr.push(data.trackedWords[i])
                    }
                    setTrackedData(trackedArr);
                    setOriginalTrackedData(trackedArr)
                    setPageLoading(false)
                })
        }
        getTrackedWords()
    }, []);

    const handleSaveKeyword = async () => {
        if (keyword) {
            const newTracked = trackedData ? trackedData.slice() : [];
            if (!newTracked.includes(keyword)) {
                newTracked.push(keyword);
                setTrackedData(newTracked);
                setDuplicateWarning(false)
                setKeyword()
                const userRef = doc(db, "users", email);
                await updateDoc(userRef, {
                    trackedWords: newTracked
                })
            } else {
                setDuplicateWarning(true)
            }
        }
    }

    const handleDeleteWord = async (word) => {
        const newTracked = trackedData.filter(e => e !== word);
        const userRef = doc(db, "users", email);
        await updateDoc(userRef, {
            trackedWords: newTracked
        })
        setTrackedData(newTracked);
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <TextInput
                    style={styles.textInput}
                    onChangeText={setKeyword}
                    placeholder="Enter Keyword"
                    placeholderTextColor='#838383'
                    value={keyword}
                    textAlign={'center'}
                />
                {duplicateWarning ? <Text style={styles.warning}>That keyword is already being tracked!</Text> : null}
                <View style={{ width: '100%', justifyContent: 'center' }}>
                    <Pressable
                        onPress={() => handleSaveKeyword()}
                        style={{ width: '100%' }}
                    >
                        <View style={{ flexDirection: 'row', marginBottom: 10, alignSelf: 'center' }}>
                            <AntDesign name="pluscircle" size={17} color={constants.ICON_COLOR} style={{ marginRight: 5 }} />
                            <Text style={[styles.textStyle, { alignSelf: 'center', color: constants.ICON_COLOR }]}>Add Keyword</Text>
                        </View>
                    </Pressable>
                </View>
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    trackedData
                        ? <View style={{ flex: 1 }}>
                            <FlatList
                                style={styles.scrollView}
                                data={trackedData}
                                renderItem={({ item }) => (
                                    <View style={[styles.button, styles.buttonClose]}>
                                        <View style={styles.rowContainer}>
                                            <View style={{ flex: 5, justifyContent: 'center' }}>
                                                <Text style={[styles.textStyle, { flex: 1, flexWrap: 'wrap' }]}>{item}</Text>
                                            </View>
                                            <View style={{ flex: .5, justifyContent: 'center' }}>
                                                <Pressable
                                                    onPress={() => handleDeleteWord(item)}
                                                >
                                                    <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    </View>
                                )}
                                keyExtractor={item => item}
                            />
                        </View>
                        : null
                }
            </View>
        </View>
    )
}

export default KeywordTracking

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        // marginTop: 95,
        height: '100%',
        flex: 1
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
    },
    textInput: {
        color: constants.SUBTEXT_COLOR,
        fontFamily: 'Verdana',
        fontSize: 16,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center',
        backgroundColor: constants.SECONDARY_DARK,
        height: 45,
        width: '70%',
        borderWidth: 1,
        borderRadius: 9,
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 10,
        marginBottom: 10,
    },
    scrollView: {
        marginBottom: 20,
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginLeft: 7,
        marginBottom: 2,
        fontSize: 13.5,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        // textAlign: "center",
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 20
        //     },
        //     android: {}
        // })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})

