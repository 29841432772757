import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ScrollView, Text, View, Linking, Pressable, Platform } from 'react-native';
import { AntDesign, EvilIcons, Ionicons, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { db, auth } from '../../db/firestore';
import { doc, getDoc } from "firebase/firestore";

const weekdayArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

const StateAgencyInfo = ({ route, navigation }) => {
    const { id } = route.params;
    const email = auth.currentUser.email;
    const [eventData, setEventData] = useState();
    const [statAgs, setStatAgs] = useState();
    const [summary, setSummary] = useState();
    const [noStart, setNoStart] = useState();
    const [month, setMonth] = useState();
    const [weekday, setWeekday] = useState();
    const [dateNum, setDateNum] = useState();
    const [time, setTime] = useState();
    const [location, setLocation] = useState();
    const [locationLink, setLocationLink] = useState(null);
    const [agenda, setAgenda] = useState(null);
    const [extraDocs, setExtraDocs] = useState(null);
    const headerHeight = useHeaderHeight();

    const getData = async () => {
        const docRef = doc(db, 'statAgEvents', id);
        await getDoc(docRef)
            .then(doc => doc.data())
            .then(event => {
                const statAgArr = [];
                const isNoDate = event.noDate ? event.noDate : false;
                const isUponAdjourn = event.uponAdjourn ? event.uponAdjourn : false;
                if (event.noDate && event.start == null) {
                    setNoStart(true)
                }

                // Convert UTC to local time
                const startTimeUTC = event.start ? new Date(event.start) : null;
                const localTime = startTimeUTC ? startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true }) : "(TBD)";

                let formattedTime = localTime;
                if (isNoDate) {
                    formattedTime = "(TBD)";
                }
                if (isUponAdjourn) {
                    formattedTime = event.adjournText ? `${event.adjournText}` : "(TBD)";
                }

                if (event.statAgs) {
                    for (let i = 0; i < event.statAgs.length; i += 1) {
                        statAgArr.push(<Text key={i} style={styles.textStyle}>{event.statAgs[i]}</Text>);
                    }
                }

                if ((event.agenda && event.useAgenda) || (event.agendaUri && !event.useAgenda)) {
                    if (event.useAgenda) {
                        setAgenda(<Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('ExternalView', { title: "Meeting Agenda", html: event.agenda, options: { headerBackTitleVisible: false } })}
                        >
                            <View style={styles.rowContainer}>
                                <MaterialCommunityIcons name="view-agenda-outline" size={24} color={constants.ICON_COLOR} />
                                <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Agenda</Text>
                            </View>
                        </Pressable>)
                    } else {
                        setAgenda(<Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('ExternalView', { title: "Meeting Agenda", link: event.agendaUri, options: { headerBackTitleVisible: false } })}
                        >
                            <View style={[styles.rowContainer]}>
                                <MaterialCommunityIcons name="view-agenda-outline" size={24} color={constants.ICON_COLOR} />
                                <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Agenda</Text>
                            </View>
                        </Pressable>)
                    }
                }

                if (event.extraDocuments?.length > 0) {
                    const docButtons = []
                    for (let i = 0; i < event.extraDocuments.length; i += 1) {
                        const item = event.extraDocuments[i];
                        docButtons.push(
                            <Pressable
                                key={item.name}
                                style={[styles.button, styles.buttonClose]}
                                onPress={() => navigation.navigate('ExternalView', { title: "Extra Documents", link: item.url, options: { headerBackTitleVisible: false } })}
                            >
                                <Text style={styles.textStyle}>{item.name}:</Text>
                            </Pressable>
                        )
                    }
                    setExtraDocs(docButtons)
                }

                setEventData(event);
                if (startTimeUTC) {
                    setWeekday(weekdayArr[startTimeUTC.getDay()]);
                    setMonth(monthNameArr[startTimeUTC.getMonth()]);
                    setDateNum(startTimeUTC.getDate());
                }
                setSummary(event.subject);
                setTime(formattedTime);
                setLocation(event.location);
                setLocationLink(event.livestreamUrl);
                setStatAgs(statAgArr);
            })
    }

    useEffect(() => {
        if (!eventData) {
            getData();
        }
    }, []);

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={[styles.rowContainer, {
                    alignSelf: 'center',
                    display: 'flex',
                    width: '96%',
                    marginBottom: 2,
                }]}
                >
                    <View style={[styles.calendarContainer]}>
                        <View style={styles.calendarMonth}>
                            <Text style={styles.monthText}>{noStart ? "No" : month}</Text>
                        </View>
                        <View style={[styles.calendarDate]}>
                            <Text style={styles.weekdayText}>{noStart ? null : weekday}</Text>
                            <Text style={[styles.dateText, { fontSize: noStart ? 11.5 : 16, marginTop: noStart ? 10 : 0 }]}>{noStart ? 'Date' : dateNum}</Text>
                        </View>
                    </View>
                    <Text style={[styles.textStyle, , { flex: 2, fontSize: 16.7 }]}>{summary}</Text>

                </View>
                <View style={[styles.button, styles.buttonClose, { paddingLeft: 0, paddingRight: 0, opacity: .7 }]}>
                    <View style={{ alignSelf: 'center', width: '98%' }}>
                        <View style={[styles.rowContainer]}>
                            <AntDesign name="team" size={17} color="white" style={{ marginTop: 3, marginBottom: 4, marginLeft: 2, marginRight: 5 }} />
                            <View>
                                {statAgs}
                            </View>
                        </View>
                        {location ?
                            <View style={styles.rowContainer}>
                                <EvilIcons name="location" size={24} color="white" style={{ marginTop: 3, marginBottom: 8, marginRight: 5 }} />
                                <Text style={{ ...styles.locationText }}>{location}</Text>
                            </View>
                            :
                            null
                        }
                        <View style={styles.rowContainer}>
                            <Ionicons name="time-outline" size={19} color="white" style={{ marginTop: -3, marginLeft: 4, marginRight: 5 }} />
                            <Text style={{ ...styles.subTextStyle, marginTop: 0 }}>{time}</Text>
                        </View>
                    </View>
                </View>

                <View>
                    {locationLink
                        ? <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => Linking.openURL(locationLink)}
                        >
                            <View style={[styles.rowContainer]}>
                                <MaterialIcons name="wifi" size={24} color={constants.ICON_COLOR} />
                                <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Livestream Link</Text>
                            </View>
                        </Pressable>
                        : null
                    }
                    {agenda ? agenda : null}
                </View>
                <ScrollView style={styles.scrollView}>
                    <View style={{ marginTop: 10 }}>
                        {extraDocs ? <Text style={[styles.textStyle, { marginLeft: 10 }]}>Extra Documents:</Text> : null}
                        {extraDocs ? extraDocs : null}

                    </View>
                </ScrollView>
            </View>
        </View >
    )
}

export default StateAgencyInfo

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        // marginTop: 100,
    },
    rowContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "95%",
    },
    scrollView: {
        marginTop: 10,
        // marginBottom: 100,
        height: '55%',
        // flexGrow: 'grow'
    },
    button: {

        // display: 'flex',
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
        // borderBottomWidth: .5,
        // borderColor: 'gray',
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarContainer: {
        backgroundColor: 'white',
        height: 51.5,
        width: 48.5,
        borderColor: 'black',
        borderWidth: 2.65,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 7,
        marginTop: 4,
        // opacity: .9
        // alignSelf: 'center',
    },
    calendarMonth: {
        // margin: -.75,
        backgroundColor: '#D21F3C',
        height: 18,
        borderColor: 'gray',
        borderWidth: .09,
        borderBottomColor: 'black',
        borderBottomWidth: 1.6,
        borderTopRightRadius: 2.5,
        borderTopLeftRadius: 2.5,
        alignItems: 'center',
    },
    calendarDate: {
        alignItems: 'center',
        // marginBottom: 8,
    },
    monthText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    weekdayText: {
        color: "#373737",
        marginBottom: -4,
        fontSize: 11,
        fontWeight: "bold",
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 15
        //     },
        //     android: {}
        // })
    },
    dateText: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 16,
        // marginTop: 3,
        // marginBottom: 2,
    },
    locationText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 6,
        fontSize: 14,
        fontWeight: "bold",
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 15
        //     },
        //     android: {}
        // })
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 15,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 6,
        fontSize: 14,
        fontWeight: "bold",
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 15
        //     },
        //     android: {}
        // })
    },
    noEntriesText: {
        color: 'white',
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlign: 'center',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    icon: {
        color: 'white',
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})