import React, { useEffect, useState } from 'react';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, Pressable, ScrollView, Platform } from 'react-native';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { getDocs, collection } from "firebase/firestore";
import { SearchBar } from 'react-native-elements'; // Import SearchBar

const OOGNewsTable = ({ route, navigation }) => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const getOrders = async () => {
        const ordersArr = [];
        const docRef = collection(db, 'oogNews');
        await getDocs(docRef)
            .then(result => result.docs)
            .then(docs => docs.map(doc => doc.data()))
            .then(docs => docs.map(doc => ({
                start: doc.start ? doc.start.substring(0, 10) : null,
                emailBody: doc.emailBody,
                time: doc.time,
                id: doc.id,
                location: doc.location,
                subject: doc.subject,
                additionalInfo: doc.additionalInfo,
            })))
            .then(arr => {
                // Sort the array by date
                arr.sort((a, b) => {
                    if (!a.start && !b.start) return 0;
                    if (!a.start) return 1;
                    if (!b.start) return -1;
                    return new Date(b.start) - new Date(a.start);
                });

                setData(arr);
                setFilteredData(arr); // Initialize filteredData with the sorted array
                setPageLoading(false);
            });
        return ordersArr;
    };

    const renderElement = (item, index) => (
        <Pressable
            key={index}
            style={[styles.button, styles.buttonClose]}
            onPress={() => navigation.navigate('OOGNewsInfo', { title: 'News', info: item, options: { headerBackTitleVisible: false } })}
        >
            <View style={styles.buttonRow}>
                <FontAwesome style={{ alignSelf: 'center', marginRight: 8, marginTop: 8 }} name="newspaper-o" size={30} color={constants.ICON_COLOR} />
                <View style={{ width: '98%' }}>
                    <Text style={styles.textStyle}>{item.subject}</Text>
                    <View style={styles.buttonRow}>
                        <AntDesign style={styles.calendarIcon} color={constants.SUBTEXT_COLOR} name="calendar" size={16} />
                        {item.start ? <Text style={styles.subTextStyle}>{item.start}</Text> : <Text style={styles.subTextStyle}>No Date</Text>}
                    </View>
                </View>
            </View>
        </Pressable>
    );

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        // Filtering logic based on searchQuery
        const filtered = data.filter(item => {
            const hasMatchingSubject = item.subject?.toLowerCase().includes(searchQuery.toLowerCase());
            const hasMatchingLocation = item.location?.toLowerCase().includes(searchQuery.toLowerCase());
            return hasMatchingSubject || hasMatchingLocation;
        });
        setFilteredData(filtered);
    }, [searchQuery, data]);

    return (
        <View style={{ flex: 1 }}>
            <SearchBar
                placeholder="Search News"
                onChangeText={(text) => setSearchQuery(text)}
                value={searchQuery}
                clearIcon
                darkTheme
                containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                inputContainerStyle={{ borderRadius: 9 }}
                inputStyle={{ color: 'white' }}
            />
            <ScrollView style={styles.scrollView}>
                {pageLoading ? (
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                ) : (
                    filteredData.length > 0 ? (
                        filteredData.map((item, index) => renderElement(item, index))
                    ) : (
                        <Text>There are no news entries at this time</Text>
                    )
                )}
            </ScrollView>
        </View>
    );
};

export default OOGNewsTable;

const styles = StyleSheet.create({
    scrollView: {},
    buttonRow: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "90%",
    },
    button: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarIcon: {
        marginTop: 10,
        marginLeft: 7
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginLeft: 7,
        fontSize: 13.2,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginTop: 10,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 15
            },
            android: {}
        })
    },
});