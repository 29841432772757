import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, Pressable, Platform } from 'react-native';
import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';
import { Dropdown } from 'react-native-element-dropdown';
import {
    buildLegisTitle,
    getHouseBills,
    getMoreHouseBills,
    getSenateBills,
    getMoreSenateBills,
    legisTitleSearch,
    handleAlgoliaSearch,
} from './prefilesHelper';
import { useIsFocused } from '@react-navigation/native';

const chamberOptions = [
    { label: 'House', value: '1' },
    { label: 'Senate', value: '2' },
];

const PrefilesTable = ({ route, navigation }) => {
    const { title, email, tier } = route.params;
    const [searchData, setSearchData] = useState([]);
    const [chamberValue, setChamberValue] = useState('1');
    const [isChamberFocus, setIsChamberFocus] = useState(false);
    const [houseData, setHouseData] = useState();
    const [senateData, setSenateData] = useState();
    const [search, setSearch] = useState('');
    const [houseStartAfter, setHouseStartAfter] = useState();
    const [senateStartAfter, setSenateStartAfter] = useState();
    const [algoliaNbPages, setAlgoliaNbPages] = useState();
    const [currentAlgoliaPage, setCurrentAlgoliaPage] = useState(1)
    const [pageLoading, setPageLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const isFocused = useIsFocused();
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        const getData = async () => {
            setPageLoading(true)
            const houseLegislation = await getHouseBills()
            const houseBills = houseLegislation.results
            setHouseStartAfter(houseLegislation.lastVisible)
            if (houseBills.length > 0) {
                setHouseData(houseBills);
            }

            const senateLegislation = await getSenateBills()
            const senateBills = senateLegislation.results
            setSenateStartAfter(senateLegislation.lastVisible)
            if (senateBills.length > 0) {
                setSenateData(senateBills);
            }
            setPageLoading(false)
        }
        getData();
    }, [isFocused]);

    const getMoreBills = async (type) => {
        if (type === 'House') {
            const houseLegislation = await getMoreHouseBills(houseStartAfter);
            const houseBills = houseLegislation.results;
            setHouseStartAfter(houseLegislation.lastVisible);
            setHouseData([...houseData, ...houseBills]);
        } else if (type === 'Senate') {
            const senateLegislation = await getMoreSenateBills(senateStartAfter);
            const senateBills = senateLegislation.results;
            setSenateStartAfter(senateLegislation.lastVisible);
            setSenateData([...senateData, ...senateBills]);
        }
    }

    const handleSearch = async (text) => {
        setSearchLoading(true)
        setSearch(text)
        const dataArr = [];
        if (text.length > 2) {
            const regex = /^(HB|HR|SB|SR)\d+$/i;
            if (regex.test(text)) {
                const billArr = await legisTitleSearch(text)
                dataArr.push(...billArr)
            } else {
                const { billArr, nbPages } = await handleAlgoliaSearch(text, 0)
                setAlgoliaNbPages(nbPages)
                setCurrentAlgoliaPage(0)
                dataArr.push(...billArr)
            }
            setSearchData(dataArr)
            setSearchLoading(false)
        } else {
            setSearchData([])
            setSearchLoading(false)
        }
    }

    const moreSearchResults = async (pageNum) => {
        if (currentAlgoliaPage > algoliaNbPages) {
            return
        } else {
            const { billArr } = await handleAlgoliaSearch(search, pageNum)
            setCurrentAlgoliaPage(pageNum)
            setSearchData([...searchData, ...billArr])
        }
    }

    const renderElement = (item) => {
        return (
            <Pressable
                key={item.id}
                style={[styles.button, styles.buttonClose]}
                onPress={() => navigation.navigate('Bill', { title: buildLegisTitle(item), billId: item.id, email: email, tier: tier, options: { headerBackTitleVisible: false } })}
            >
                <View style={styles.rowContainer}>
                    <View style={{ flex: 1.2 }}>
                        <Text style={styles.textStyle}>{buildLegisTitle(item)}</Text>
                    </View>
                    <View style={{ flex: 5 }}>
                        <Text style={styles.subTextStyle}>{item.title}</Text>
                        <Text style={[styles.subTextStyle, { fontSize: 11, marginTop: 5, opacity: .6 }]}>Status: {item.status}</Text>
                    </View>
                    <View style={{ flex: .5 }}>
                        <MaterialCommunityIcons name="dots-vertical" size={26} color={constants.ICON_COLOR} />
                    </View>
                </View>
            </Pressable>
        )
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <Dropdown
                    style={[styles.dropdown, isChamberFocus && { borderColor: 'blue' }, search.length > 0 && styles.hidden]}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    fontFamily='Verdana'
                    containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                    itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                    itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                    activeColor='#3f3f3f'
                    inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                    iconStyle={styles.iconStyle}
                    data={chamberOptions}
                    renderLeftIcon={() => (
                        <FontAwesome style={styles.icon} name="building-o" size={18} color={constants.ICON_COLOR} />
                    )}
                    // search
                    maxHeight={300}
                    labelField="label"
                    valueField="value"
                    placeholder={!isChamberFocus ? 'All' : '...'}
                    searchPlaceholder="Search..."
                    value={chamberValue}
                    onFocus={() => setIsChamberFocus(true)}
                    onBlur={() => setIsChamberFocus(false)}
                    onChange={item => {
                        setChamberValue(item.value);
                        setIsChamberFocus(false);
                    }}
                />
                <SearchBar
                    placeholder="Search Prefiles"
                    onChangeText={(text) => handleSearch(text)}
                    value={search}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                />
                {searchLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} style={search.length > 0 ? styles.shown : styles.hidden} />
                    :
                    <FlatList
                        style={search.length > 0 ? styles.flatlist : styles.hidden}
                        data={searchData}
                        renderItem={({ item }) => renderElement(item)}
                        keyExtractor={item => item.id}
                        onEndReached={() => moreSearchResults(currentAlgoliaPage + 1)}
                        onEndReachedThreshold={0.01}
                        scrollEventThrottle={150}
                    />
                }
                {/* <Text style={search.length > 0 ? styles.hidden : styles.textStyle}>House</Text> */}
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    chamberValue === '1' ?
                        <FlatList
                            style={search.length > 0 ? styles.hidden : styles.flatlist}
                            data={houseData}
                            renderItem={({ item }) => renderElement(item)}
                            keyExtractor={item => item.id}
                            onEndReached={() => getMoreBills('House')}
                            onEndReachedThreshold={0.01}
                            scrollEventThrottle={150}
                        />
                        :
                        <FlatList
                            style={search.length > 0 ? styles.hidden : styles.flatlist}
                            data={senateData}
                            renderItem={({ item }) => renderElement(item)}
                            keyExtractor={item => item.id}
                            onEndReached={() => getMoreBills('Senate')}
                            onEndReachedThreshold={0.01}
                            scrollEventThrottle={150}
                        />
                }
                {/* <Text style={search.length > 0 ? styles.hidden : styles.textStyle}>Senate</Text> */}
            </View>
        </View>
    )
}

export default PrefilesTable

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1
    },
    rowContainer: {
        flexDirection: 'row',
        width: "100%",
        alignItems: 'center'
    },
    hidden: {
        display: 'none'
    },
    shown: {
        display: 'flex'
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        width: 170,
        borderColor: 'gray',
        borderWidth: 1.5,
        borderRadius: 9,
        paddingHorizontal: 8,
        marginBottom: 10,
        marginTop: 10,
        alignSelf: 'center'
    },
    placeholderStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    selectedTextStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    icon: {
        marginLeft: 4,
        marginRight: 10,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    flatlist: {
        marginBottom: 20,
        flexGrow: 1
    },
    searchFlatlist: {
        height: 700
    },
    button: {
        minHeight: 60,
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 3,
        paddingRight: 3,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        opacity: .7,
        fontSize: 14.5,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        alignSelf: "center",
    },
    subTextStyle: {
        flexGrow: 0,
        color: constants.SUBTEXT_COLOR,
        width: "90%",
        marginLeft: 18,
        fontSize: 14,
        fontFamily: 'Verdana',
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})
