import React, { useState, useEffect } from "react";
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../utils/constants";
import { StyleSheet, Text, View, Pressable, Image, Platform, ScrollView } from 'react-native'
// import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants'
import { AntDesign, Entypo, Fontisto, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import { db, auth } from '../db/firestore';
import { doc, updateDoc, getDoc } from "firebase/firestore";

// Notifications.setNotificationHandler({
//     handleNotification: async () => ({
//         shouldShowAlert: true,
//         shouldPlaySound: false,
//         shouldSetBadge: false,
//     })
// })

const Home = ({ navigation }) => {
    const [email, setEmail] = useState()
    const [tier, setTier] = useState('1')
    const [userInfo, setUserInfo] = useState()
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        if (!email) {
            const user = auth.currentUser;
            setEmail(user.email)
            getUserInfo(user.email)
        } else {
            // registerForPushNotifications();
        }
        // Notifications.setBadgeCountAsync(0);
    }, [userInfo])

    const getUserInfo = async (userEmail) => {
        const userRef = doc(db, "users", userEmail);
        await getDoc(userRef)
            .then(doc => doc.data())
            .then(user => {
                setTier(user.tier)
                setUserInfo(user)
            })
    }

    // const registerForPushNotifications = async () => {
    //     let userTokens;
    //     const tokens = []
    //     const { status: existingStatus } = await Notifications.getPermissionsAsync()
    //     let finalStatus = existingStatus;
    //     if (existingStatus !== "granted") {
    //         const { status } = await Notifications.requestPermissionsAsync()
    //         finalStatus = status;
    //     }

    //     if (finalStatus !== "granted") {
    //         return;
    //     }

    //     let token = await Notifications.getExpoPushTokenAsync({
    //         projectId: Constants.expoConfig.extra.eas.projectId,
    //     });
    //     if (userInfo.expoPushTokens) {
    //         tokens.push(...userInfo.expoPushTokens)
    //         if (!userInfo.expoPushTokens.includes(token.data)) {
    //             tokens.push(token.data)
    //         }
    //     } else {
    //         tokens.push(token.data)
    //     }
    //     userTokens = tokens
    //     const userRef = doc(db, "users", userInfo.email);
    //     await updateDoc(userRef, {
    //         expoPushTokens: userTokens
    //     })

    //     if (Platform.OS === 'android') {
    //         Notifications.setNotificationChannelAsync('default', {
    //             name: 'default',
    //             importance: Notifications.AndroidImportance.MAX,
    //             vibrationPattern: [0, 250, 250, 250],
    //             lightColor: '#FF231F7C'
    //         });
    //     }
    // }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK, flex: 1 }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <ScrollView >
                    <View style={styles.centeredView}>
                        <Pressable
                            disabled={tier === '2' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '2' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('StateAgenciesTable', { title: "State Departments", email: email })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '2' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <MaterialCommunityIcons name="clipboard-text-outline" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>State Agency Events</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('MeetingsTable', { title: "Legislative Meetings", email: email, options: { headerBackTitleVisible: false } })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="team" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Legislative Meetings</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('CalendarsTable', { title: "Calendar", email: email })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="calendar" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Legislative Calendars</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('BillTable', { title: "My Legislation", email: email })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="filetext1" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Lobby Report Page</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => {
                                navigation.navigate('AdditionalInfo', { title: "Additional Info", email: email, tier: tier })
                            }}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                <Entypo name="dots-three-horizontal" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                <Text style={styles.textStyle}>Additional Information</Text>
                            </View>
                        </Pressable>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

export default Home

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: constants.PRIMARY_DARK,
        // marginTop: 100,
    },
    centeredView: {
        backgroundColor: constants.PRIMARY_DARK,
        // flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 40
    },
    button: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: "center",
        borderRadius: 9,
        borderWidth: 2,
        borderColor: '#131313',
        // padding: 10,
        elevation: 2,
        width: "95%",
        // height: 100,
        marginBottom: 10,
        // alignItems: "center",
        // shadowColor: "white",
        // shadowOffset: {
        //     width: 1,
        //     height: 2
        // },
        // shadowOpacity: 0.07,
        // shadowRadius: 3,
        // elevation: 5,
        ...Platform.select({
            ios: {
                height: 100,
                padding: 10,
            },
            android: {
                height: 88,
                padding: 3,
            },
            web: {
                height: 100,
                padding: 10,
            }
        })
    },
    buttonOpen: {
        backgroundColor: "black",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    buttonIcon: {
        marginLeft: '-5%',
        marginTop: 25,
        // opacity: .45,
    },
    viewWithShadow: {

        backgroundColor: 'black',
        borderRadius: 20,
        // flexWrap: "wrap",
        height: 32,
        width: 20,
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        // !!!!!!!!--------change this to 7 to demo the shadow---------!!!!
        shadowRadius: 0,
        elevation: 5,
    },
    imageShadow: {
        marginTop: 20,
        marginRight: 15,
        height: 32,
        width: 20,
        borderRadius: 16,
        backgroundColor: 'black',
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: .5,
        // !!!!!!!!--------change this to 7 to demo the shadow---------!!!!
        shadowRadius: 0,
        elevation: 5,
    },
    textStyle: {
        marginTop: 15,
        marginLeft: 10,
        fontSize: 17,
        fontWeight: "bold",
        color: constants.TITLE_COLOR,
        lineHeight: 45,
        // textAlignVertical: "center",
        fontFamily: 'Verdana',
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 45
        //     },
        //     android: {
        //         lineHeight: 45
        //     }
        // })
    },
})
