import { StyleSheet, Text, View, Image, Platform  } from 'react-native'
import constants from '../utils/constants'
import React from 'react'

const Footer = () => {
    return (
        <View style={{backgroundColor: constants.PRIMARY_DARK}}>
            <View style={styles.container}>
                <Image
                    style={styles.tinyLogo}
                    source={require('../assets/lion2-removebg-preview.png')}
                />
            </View>
        </View>
    )
}

export default Footer

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 100,
        backgroundColor: constants.PRIMARY_DARK,
        bottom: 0,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        ...Platform.select({
            ios: {
                height: 65,
            },
            android: {
                height: 40,
            }
        })
    },
    text: {
        color: "white",
        fontFamily: "Verdana",
        fontWeight: "500",
        fontSize: 12
    },
    tinyLogo: {
        marginRight: 3,
        marginBottom: 5,
        borderRadius: 10,
        ...Platform.select({
            ios: {
                height: 40,
                width: 80,
            },
            android: {
                height: 30,
                width: 60,
                marginTop: 15
            }
        })
    },
})