export default {
    // PRIMARY_DARK: '#161616',
    // PRIMARY_DARK: '#191919',
    // PRIMARY_DARK: '#1D1D1D',
    PRIMARY_DARK: '#121212',
    // SECONDARY_DARK: '#232223',
    SECONDARY_DARK: '#232021',
    // ICON_COLOR: "#00A9AF",
    // ICON_COLOR: "#009497",
    // ICON_COLOR: "#008080",
    ICON_COLOR: "#009090",
    HEADER_TEXT_COLOR: 'white',
    TITLE_COLOR: '#E2E2E2',
    // TITLE_COLOR: 'white',
    SUBTEXT_COLOR: "#e5e5e5"
};