import React, { useState, useEffect, useRef } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import { SafeAreaView, StyleSheet, View, Text, Linking, Alert, ActivityIndicator } from 'react-native';
import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, query, where, orderBy } from "firebase/firestore";
import axios from 'axios';
import PDFViewer from './PDFViewer';
import CalendarButtonModal from './CalendarButtonModal';
import constants from '../../utils/constants';

const chamberType = {
    H: 1,
    S: 2,
};
const documentType = {
    B: 1,
    R: 2,
};

const legisChamberType = {
    1: "H",
    2: "S",
};
const legisDocumentType = {
    1: "B",
    2: "R",
};

const buildLegisTitle = (item) => {
    return `${legisChamberType[item.chamber]}${legisDocumentType[item.documentType]}${item.number}`;
};

const ExternalView = ({ route, navigation }) => {
    const { link, calendar, html, isBill, member } = route.params;
    const [acceptedLink, setAcceptedLink] = useState(false);
    const [acceptedPdf, setAcceptedPdf] = useState(false);
    const [formattedLink, setFormattedLink] = useState();
    const [formattedHtml, setFormattedHtml] = useState();
    const headerHeight = useHeaderHeight();
    const htmlRef = useRef(null);

    useEffect(() => {
        if (html) {
            if (html.startsWith('"') && html.endsWith('"')) {
                setFormattedHtml(html.slice(1, -1));
            } else {
                setFormattedHtml(html);
            }
        }
        if (member) {
            setAcceptedLink(true);
            setFormattedLink(`https://www.legis.ga.gov/members/${member.chamber}/${member.memberId}?session=1031`)
        }
        if (link && !html && (calendar || isBill)) {
            setFormattedLink(link.replaceAll('http:', 'https:').replaceAll('"', ''));
            setAcceptedPdf(true)
        } else if (link && !calendar && !isBill && !html) {
            if ((link.includes('firebasestorage')) && link.includes('.pdf')) {
                setFormattedLink(link.replaceAll('http:', 'https:').replaceAll('"', ''));
                setAcceptedPdf(true)
            } else if (link.includes('house-press.com') || link.includes('www.senate.ga.gov') || link.includes('www.house.ga.gov')) {
                setAcceptedLink(true);
                setFormattedLink(link.replaceAll('http:', 'https:').replaceAll('"', ''));
            } else if ((link.includes('gov.georgia.gov/document') && link.includes('download')) || (!link.includes('firebasestorage') && link.includes('.pdf'))) {
                fetchContent(link.replaceAll('http:', 'https:').replaceAll('"', ''));
            } else {
                checkIframe(link.replaceAll('http:', 'https:').replaceAll('"', ''));
            }
        }
    }, []);

    const fetchContent = async (changedLink) => {
        try {
            // Check if the PDF can be accessed without CORS issues
            await axios.get(changedLink, { responseType: 'arraybuffer', withCredentials: false });
            setAcceptedPdf(true);
            setFormattedLink(changedLink);
        } catch (error) {
            // If there's a CORS error, fetch via server
            try {
                const response = await axios.get(`https://api.clarityinfo.org/fetch-content?url=${encodeURIComponent(changedLink)}`, {
                    responseType: 'arraybuffer'
                });
                const contentType = response.headers['content-type'];
                if (contentType.includes('application/pdf')) {
                    const blob = new Blob([response.data], { type: contentType });
                    const url = URL.createObjectURL(blob);
                    setAcceptedPdf(true);
                    setFormattedLink(url);
                } else {
                    setAcceptedLink(true)
                    setFormattedLink(changedLink)
                }
            } catch (serverError) {
                console.error('Error fetching content from server', serverError);
            }
        }
    };

    const checkIframe = async (url) => {
        if (link.includes('www.legis.ga.gov')) {
            setAcceptedLink(true);
            setFormattedLink(url);
        } else {
            try {
                await axios.get(url, { withCredentials: false });
                setAcceptedLink(true);
                setFormattedLink(url);
            } catch (error) {
                window.open(url, '_blank');
                navigation.goBack();
            }
        }
    }

    const goToLegislation = async (legis) => {
        const docRef = collection(db, 'legislation');
        const q = query(docRef,
            where('chamber', '==', chamberType[legis[0]]),
            where('documentType', '==', documentType[legis[1]]),
            where('number', '==', legis.substring(2)),
            orderBy("number")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const billId = doc.data().legislationId;
            navigation.navigate('Bill', { title: legis, billId, options: { headerBackTitleVisible: false } });
        });
    };

    const goToBillByVersion = async (versionId) => {
        const version = Number(versionId);
        const q = query(collection(db, "legislation"), where("version_ids", "array-contains", version));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
            results.push(doc.data());
        });

        if (results.length >= 1) {
            const billTitle = buildLegisTitle(results[0]);
            navigation.navigate('Bill', { title: billTitle, billId: results[0].id, options: { headerBackTitleVisible: false } });
        } else {
            console.error("No such document!");
        }
    };

    const goToBillById = async (id) => {
        const docRef = doc(db, 'legislation', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const billData = docSnap.data();
            const billTitle = buildLegisTitle(billData);
            navigation.navigate('Bill', { title: billTitle, billId: id, options: { headerBackTitleVisible: false } });
        } else {
            console.error("No such document!");
        }
    };

    const handleLinkClick = (uri) => {
        let formattedUri = uri;
        if ((uri.indexOf('%22', 5) !== -1) && (uri.startsWith('http://localhost') || uri.startsWith('https://capitolintel'))) {
            formattedUri = uri.slice(uri.indexOf('%22', 5) + 3).replaceAll('/%22', '').replaceAll('\\%22', '')
        }
        if (uri.startsWith('mailto:')) {
            Linking.openURL(uri).catch(err => Alert.alert('Error', `Failed to open email link: ${err.message}`));
        } else if (uri.startsWith('tel:')) {
            Linking.openURL(uri).catch(err => Alert.alert('Error', `Failed to open phone link: ${err.message}`));
        } else {
            let legis;
            if (uri.indexOf('HB') >= 1) {
                legis = uri.substring(uri.indexOf("HB")).replace('/', '');
            } else if (uri.indexOf('HR') >= 1) {
                legis = uri.substring(uri.indexOf("HR")).replace('/', '');
            } else if (uri.indexOf('SB') >= 1) {
                legis = uri.substring(uri.indexOf("SB")).replace('/', '');
            } else if (uri.indexOf('SR') >= 1) {
                legis = uri.substring(uri.indexOf("SR")).replace('/', '');
            } else if (uri.indexOf('.pdf') >= 1) {
                const match = uri.match(/\/(\d+)\.pdf$/);
                if (match) {
                    const versionId = match[1];
                    goToBillByVersion(versionId);
                }
            }

            if (legis) {
                goToLegislation(legis);
            } else {
                // Check if it's the second link format
                const match = uri.match(/https:\/\/www\.legis\.ga\.gov\/legislation\/(\d+)/);
                if (match && match[1]) {
                    const billId = match[1];
                    goToBillById(billId);
                } else {
                    // Default to opening link in a new tab
                    window.open(formattedUri, '_blank');
                }
            }
        }
    };

    useEffect(() => {
        const iframe = htmlRef.current;
        if (iframe) {
            iframe.addEventListener('load', () => {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const links = iframeDoc.querySelectorAll('a');
                links.forEach(link => {
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        handleLinkClick(e.target.href)
                    });
                });
            });
        }
    }, [formattedHtml]);

    return (
        <SafeAreaView style={{ flex: 1 }}>
            {(formattedLink && acceptedPdf) ?
                <View style={[styles.container, { marginTop: headerHeight }]}>
                    <PDFViewer
                        pdfUrl={formattedLink}
                        onLinkClick={handleLinkClick}
                    />
                    <CalendarButtonModal calendar={calendar} goToLegislation={goToLegislation} />
                </View>
                : formattedHtml ?
                    <View style={[styles.container, { marginTop: headerHeight }]}>
                        <iframe id="contentFrame" ref={htmlRef} srcDoc={formattedHtml} style={styles.iframe} />
                    </View>
                    : (formattedLink && acceptedLink) ?
                        <View style={[styles.container, { marginTop: headerHeight }]}>
                            <iframe src={formattedLink} style={styles.iframe} />
                        </View>
                        :
                        <View style={[styles.container, { marginTop: headerHeight }]}>
                            <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                        </View>
            }
        </SafeAreaView>
    );
};

export default ExternalView;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iframe: {
        height: '100%',
        width: '100%',
        borderWidth: 0,
    },
    unsupportedText: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        color: constants.TITLE_COLOR,
    },
});