import React, { useState } from "react";
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, TextInput, Alert, Linking, Platform } from 'react-native'
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../db/firestore';

const PasswordReset = ({ navigation }) => {
    const [email, setEmail] = useState(null);

    const handleSubmit = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                Alert.alert('Password Reset Sent!', 'An email was sent to you with steps to reset your passowrd.', [
                    {text: 'OK', onPress: () => navigation.goBack()},
                  ]);
            })
            .catch((error) => {
                console.log(error)
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // ..
            });
    }

    return (

        <View style={styles.centeredView}>
            <View style={styles.modalView}>
                <View style={{width: '84%'}}>
                    <Text style={{fontFamily: 'Verdana', fontWeight: 'bold'}}>Enter your email to reset your password</Text>
                </View>
                <TextInput
                    style={styles.input}
                    autoCorrect={false}
                    autoCapitalize={'none'}
                    keyboardType={'email-address'}
                    onChangeText={setEmail}
                    value={email}
                    placeholder="Email"
                />
                <Pressable
                    style={[styles.button, styles.buttonClose]}
                    onPress={handleSubmit}
                >
                    <Text style={styles.textStyle}>Send Password Reset Email</Text>
                </Pressable>
            </View>
        </View>

    )
}

export default PasswordReset

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        // marginTop: -300,
        fontFamily: 'Verdana'
    },
    modalView: {
        margin: 20,
        width: "80%",
        // height: "85%",
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        marginBottom: 10
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: constants.ICON_COLOR,
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    input: {
        height: 40,
        width: "80%",
        margin: 12,
        borderWidth: .5,
        padding: 10,
        borderRadius: 10,
    }
})
