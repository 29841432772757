import constants from './utils/constants';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Image, TouchableOpacity, View, Linking, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'; 
import Footer from './screens/Footer';
import LogIn from './screens/LogIn'
import PasswordReset from './screens/passwordReset/PasswordReset'
import Home2 from './screens/Home2';
import AdditionalInfo from './screens/additionalInfo/AdditionalInfo'
import Account from './screens/account/Account';
import Profile from './screens/account/Profile'
import CommitteeTracking from './screens/account/CommitteeTracking'
import KeywordTracking from './screens/account/KeywordTracking'
import LegislationTracking from './screens/account/LegislationTracking'
import StatAgTracking from './screens/account/StatAgTracking'
import ExternalView from './screens/utils/ExternalView';
import MeetingsTable from './screens/meetings/MeetingsTable'
import Meeting from './screens/meetings/Meeting'
import CalendarsTable from './screens/calendars/CalendarsTable'
import BillTable from './screens/bills/BillTable'
import Bill from './screens/bills/Bill'
import AIAnalysis from './screens/aIAnalysis/AIAnalysis'
import VotePage from './screens/utils/VotePage';
import PrefilesTable from './screens/prefiles/PrefilesTable'
import SignedLegislationTable from './screens/signedLegislation/SignedLegislationTable'
import OOGOrdersTable from './screens/oog/OOGOrdersTable'
import OOGNewsTable from './screens/oog/OOGNewsTable'
import OOGNewsInfo from './screens/oog/OOGNewsInfo'
import OOGContainer from './screens/oog/OOGContainer'
import StateAgenciesTable from './screens/stateAgencies/StateAgenciesTable'
import StateAgencyInfo from './screens/stateAgencies/StateAgencyInfo'
import GoogleCalendars from './screens/account/GoogleCalendars';
import ContactInfo from './screens/contactInfo/ContactInfo';

SplashScreen.preventAutoHideAsync();
const splashTimeout = () => {
  SplashScreen.hideAsync()
}

setTimeout(splashTimeout, 3000);


const Stack = createNativeStackNavigator();

export default function App() {

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={({ navigation }) => ({
          contentStyle:{backgroundColor: constants.PRIMARY_DARK},
          backgroundColor: constants.PRIMARY_DARK,
          headerTransparent: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: constants.PRIMARY_DARK,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
          },
          headerTintColor: constants.TITLE_COLOR,
          headerTitleStyle: {
            fontWeight: "500",
            fontSize: 19,
            color: constants.HEADER_TEXT_COLOR,
            fontFamily: 'Verdana'
          },
          headerRight: () => (
            <TouchableOpacity
              style={[styles.button, {marginRight: 20}]}
              onPress={() => navigation.navigate('Account', { title: "Account", options: { headerBackTitleVisible: false } })}
            >
              <FontAwesome5 name="user-circle" size={24} color={constants.ICON_COLOR} />
            </TouchableOpacity>
          )
        })}
      >
        <Stack.Screen name="LogIn" component={LogIn} options={({ route, navigation }) => ({
          title: "Log In",
          headerTitle: () => <Image style = {{height: 20, width: 190, borderRadius: 7}} source={require('./assets/capitolIntel-removebg-preview.png')} />,
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        })} />
        <Stack.Screen name="PasswordReset" component={PasswordReset} options={({ route, navigation }) => ({
          title: "Reset Password",
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        })} />
        <Stack.Screen name="Home" component={Home2} options={({ navigation }) => ({
          headerTitle: () => <Image style = {{height: 20, width: 190, borderRadius: 7}} source={require('./assets/capitolIntel-removebg-preview.png')} />,
        })} />
        <Stack.Screen name="AdditionalInfo" component={AdditionalInfo} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="ExternalView" component={ExternalView} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="ContactInfo" component={ContactInfo} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="Account" component={Account} options={{
          title: 'Account',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="Profile" component={Profile} options={({ navigation }) => ({
          title: 'Profile',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        })
        } />
        <Stack.Screen name="CommitteeTracking" component={CommitteeTracking} options={{
          title: 'Committee Tracking',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="KeywordTracking" component={KeywordTracking} options={{
          title: 'Keyword Tracking',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="LegislationTracking" component={LegislationTracking} options={{
          title: 'Legislation Tracking',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="StatAgTracking" component={StatAgTracking} options={{
          title: 'State Agency Tracking',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="GoogleCalendars" component={GoogleCalendars} options={{
          title: 'Google Calendars',
          headerBackTitleVisible: false,
          headerRight: () => (
            <View></View>
          )
        }} />
        <Stack.Screen name="MeetingsTable" component={MeetingsTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="Meeting" component={Meeting} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="CalendarsTable" component={CalendarsTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="BillTable" component={BillTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="Bill" component={Bill} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="VotePage" component={VotePage} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="AIAnalysis" component={AIAnalysis} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="PrefilesTable" component={PrefilesTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="SignedLegislationTable" component={SignedLegislationTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="OOGContainer" component={OOGContainer} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="OOGOrdersTable" component={OOGOrdersTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="OOGNewsTable" component={OOGNewsTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="OOGNewsInfo" component={OOGNewsInfo} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="StateAgenciesTable" component={StateAgenciesTable} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
        <Stack.Screen name="StateAgencyInfo" component={StateAgencyInfo} options={({ route, navigation }) => ({
          title: route.params.title,
        })} />
      </Stack.Navigator>
      <Footer/>
      <StatusBar style="light" />
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  tinyLogo: {
    width: 33,
    height: 33,
    opacity: .8
  },
});
