import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, Button, Alert, Pressable, Platform } from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';
import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, updateDoc, collection, query, where, orderBy } from "firebase/firestore";
import { searchAlgolia } from '../../db/algolia.js';

const LegislationTracking = ({ route }) => {
    const { email } = route.params;
    const [legislation, setLegislation] = useState([]);
    const [legislationTitles, setLegislationTitles] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [algoliaNbPages, setAlgoliaNbPages] = useState();
    const [currentAlgoliaPage, setCurrentAlgoliaPage] = useState();
    const [pageLoading, setPageLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [billSearch, setBillSearch] = useState(false);
    const headerHeight = useHeaderHeight();

    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };

    const buildLegisTitle = (item) => {
        return `${chamberType[item.chamber]}${documentType[item.documentType]}${item.number}`;
    }

    useEffect(() => {
        async function getTrackedLegislation() {
            const docRef = doc(db, 'users', email);
            await getDoc(docRef)
                .then(doc => doc.data())
                .then(data => {
                    const trackedArr = [];
                    const trackedTitles = [];
                    for (let i = 0; i < data.trackedLegislation.length; i += 1) {
                        trackedArr.push(data.trackedLegislation[i])
                    }
                    for (let i = 0; i < data.trackedLegislationTitles.length; i += 1) {
                        trackedTitles.push(data.trackedLegislationTitles[i])
                    }
                    setLegislation(trackedArr)
                    setLegislationTitles(trackedTitles)
                    setPageLoading(false)
                })
        }
        getTrackedLegislation()
    }, []);

    const handleSave = async (item) => {
        const newLegArr = legislation;
        const newTitleArr = legislationTitles;
        newLegArr.push({
            id: item.id,
            title: item.title,
            legislationTitle: buildLegisTitle(item),
        });
        newTitleArr.push(buildLegisTitle(item))
        setLegislation(newLegArr)
        setLegislationTitles(newTitleArr)
        handleSearch(search)
        const userRef = doc(db, "users", email);
        await updateDoc(userRef, {
            trackedLegislation: newLegArr,
            trackedLegislationTitles: newTitleArr,
        })
    }

    const legisTitleSearch = async (text) => {
        const chamberNumType = {
            H: 1,
            S: 2,
        };
        const documentNumType = {
            B: 1,
            R: 2,
        };
        const formattedText = text.toUpperCase();
        const docRef = collection(db, 'legislation');
        const q = await query(docRef,
            where('chamber', '==', chamberNumType[formattedText[0]]),
            where('documentType', '==', documentNumType[formattedText[1]]),
            where('number', '==', formattedText.substring(2)),
            orderBy("number")
        )
        return await getDocs(q)
            .then((querySnapshot) => {
                const trackedBillArr = [];
                querySnapshot.forEach((doc) => {
                    const item = doc.data()
                    trackedBillArr.push({
                        chamber: item.chamber,
                        documentType: item.documentType,
                        number: item.number,
                        title: item.title,
                        id: item.legislationId,
                        status: item.status,
                    })
                })
                return trackedBillArr.slice()
            })
    }

    const handleAlgoliaSearch = async (text, pageNum) => {
        const billArr = [];
        const { hits, nbPages } = await searchAlgolia(text, pageNum)
        for (let i = 0; i < hits.length; i += 1) {
            billArr.push({
                chamber: hits[i].chamber,
                documentType: hits[i].documentType,
                number: hits[i].number,
                title: hits[i].title,
                id: hits[i].legislationId,
                status: hits[i].status,
            })
        }
        return { billArr, nbPages }
    }

    const handleSearch = async (text) => {
        setSearch(text)
        const dataArr = [];
        if (text.length > 2) {
            setSearchLoading(true)
            const regex = /^(HB|HR|SB|SR)\d+$/i;
            if (regex.test(text)) {
                setBillSearch(true)
                const billArr = await legisTitleSearch(text)
                dataArr.push(...billArr)
            } else {
                setBillSearch(false)
                const { billArr, nbPages } = await handleAlgoliaSearch(text, 0)
                setAlgoliaNbPages(nbPages)
                setCurrentAlgoliaPage(0)
                dataArr.push(...billArr)
            }
            setData(dataArr)
            setSearchLoading(false)
        } else {
            setData([])
            setSearchLoading(false)
        }

    }

    const moreSearchResults = async (pageNum) => {
        if (!billSearch) {
            if (currentAlgoliaPage > algoliaNbPages) {
                return
            } else {
                const { billArr } = await handleAlgoliaSearch(search, pageNum)
                setCurrentAlgoliaPage(pageNum)
                setData([...data, ...billArr])
            }
        }
    }

    const handleDeleteBill = async (item) => {
        let newTracked;
        let newTrackedTitles;
        if (item.legislationTitle) {
            newTracked = legislation.filter(e => e.legislationTitle !== item.legislationTitle);
            newTrackedTitles = legislationTitles.filter(e => e !== item.legislationTitle);
        } else {
            newTracked = legislation.filter(e => e.legislationTitle !== buildLegisTitle(item));
            newTrackedTitles = legislationTitles.filter(e => e !== buildLegisTitle(item));

        }
        const userRef = doc(db, "users", email);
        await updateDoc(userRef, {
            trackedLegislation: newTracked,
            trackedLegislationTitles: newTrackedTitles,
        })
        setLegislation(newTracked);
        setLegislationTitles(newTrackedTitles)
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <SearchBar
                    placeholder="Search legislation to track"
                    onChangeText={(text) => handleSearch(text)}
                    value={search}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                />
                {searchLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} style={search.length > 0 ? styles.shown : styles.hidden} />
                    :
                (data.length > 0)
                    ? <FlatList
                        style={styles.scrollView}
                        data={data}
                        renderItem={({ item }) => (
                            <View
                                style={[styles.button, styles.buttonClose]}
                            >
                                <View style={styles.rowContainer}>
                                    <View style={{ flex: 5, justifyContent: 'center' }}>
                                        <Text style={styles.textStyle}>{buildLegisTitle(item)}: {item.title}</Text>
                                    </View>
                                    <View style={{ flex: .5, justifyContent: 'center' }}>
                                        {(legislation.some(bill => bill.id === item.id)) ?
                                            <Pressable
                                                onPress={() => handleDeleteBill(item)}
                                            >
                                                <MaterialIcons name="cancel" size={24} color="gray" />
                                            </Pressable>
                                            :
                                            <Pressable
                                                onPress={() => handleSave(item)}
                                            >
                                                <AntDesign name="pluscircle" size={24} color={constants.ICON_COLOR} />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                        )}
                        keyExtractor={item => item.id}
                        onEndReached={() => moreSearchResults(currentAlgoliaPage + 1)}
                        onEndReachedThreshold={0.01}
                        scrollEventThrottle={150}
                    />
                    : null
                }
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    (legislation.length > 0 && data.length === 0) ?
                        <View style={{flex: 1}}>
                            <View style={{ margin: 15 }}>
                                <Text style={styles.textStyle}>Tracked Legislation</Text>
                            </View>
                            <FlatList
                                style={styles.scrollView}
                                data={legislation}
                                renderItem={({ item }) => (
                                    <View style={[styles.button, styles.buttonClose]}>
                                        <View style={styles.rowContainer}>
                                            <View style={{ flex: 5, justifyContent: 'center' }}>
                                                <Text style={[styles.textStyle, { flex: 1, flexWrap: 'wrap' }]}>{item.legislationTitle}: {item.title}</Text>
                                            </View>
                                            <View style={{ flex: .5, justifyContent: 'center' }}>
                                                <Pressable
                                                    onPress={() => handleDeleteBill(item)}
                                                >
                                                    <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            />
                        </View>
                        : null
                }
            </View>
        </View>
    )
}

export default LegislationTracking

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        height: '100%',
        flex: 1
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
    },
    scrollView: {
        marginBottom: 20,
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        width: "90%",
        marginLeft: 7,
        marginBottom: 2,
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})