import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, Button, TextInput, ScrollView, FlatList, Pressable, SafeAreaView, Platform } from 'react-native';
import { AntDesign, Fontisto, MaterialCommunityIcons, MaterialIcons, Ionicons } from '@expo/vector-icons';
import firebase from 'firebase/compat/app';
import { db, auth } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useIsFocused } from '@react-navigation/native';
import * as MailComposer from 'expo-mail-composer';
// Print will be for adding pdf's to email. 
// Might need this in other files so dont delete.
import * as Print from 'expo-print';

const Bill = ({ route, navigation }) => {
    const { billId, title } = route.params
    const email = auth.currentUser.email;
    const [billInfo, setBillInfo] = useState();
    const [trackedLegislation, setTrackedLegislation] = useState()
    const [sponsors, setSponsors] = useState();
    const [committees, setCommittees] = useState();
    const [statusHistory, setStatusHistory] = useState();
    const [votes, setVotes] = useState();
    const [footnotes, setFootnotes] = useState()
    const [value, setValue] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [billHistory, setBillHistory] = useState()
    const [showBillHistory, setShowBillHistory] = useState(false)
    const [showSponsors, setShowSponsors] = useState(false)
    const [showCommittees, setShowCommittees] = useState(false)
    const [showStatusHistory, setShowStatusHistory] = useState(false)
    const [showFootnotes, setShowFootnotes] = useState(false)
    const [showVotes, setShowVotes] = useState(false)
    const [userData, setUserData] = useState()
    const [pdfUrl, setPdfUrl] = useState('')
    // set isAvailable to false once testing email
    const [isAvailable, setIsAvailable] = useState(true);
    const [historyEnabled, setHistoryEnabled] = useState(false)
    const [emailBillHistory, setEmailBillHistory] = useState('')
    const [emailCommittees, setEmailCommittees] = useState('')
    const [emailSponsors, setEmailSponsors] = useState('')
    const [emailStatusHistory, setEmailStatusHistory] = useState('')
    const [emailVotes, setEmailVotes] = useState('')
    const [recipients, setRecipients] = useState([]);
    const [subject, setSubject] = useState(undefined);
    const [body, setBody] = useState(undefined);
    const [pageLoading, setPageLoading] = useState(true);
    // const [email, setEmail] = useState(undefined);
    const isFocused = useIsFocused();
    const headerHeight = useHeaderHeight();

    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };
    const chamberObj = {
        1: "House",
        2: "Senate",
    }

    const buildLegisTitle = (billInfo) => {
        return `${chamberType[billInfo.chamber]}${documentType[billInfo.documentType]}${billInfo.number}`;
    }

    const toggleSwitch = async () => {
        if (!isEnabled) {
            const trackedArr = trackedLegislation;
            trackedArr.push({
                title: billInfo.title,
                id: billInfo.id,
                legislationTitle: buildLegisTitle(billInfo),
            });
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                trackedLegislation: trackedArr
            });
            setIsEnabled(true)
        } else {
            const billToRemove = {
                title: billInfo.title,
                id: billInfo.id,
                legislationTitle: buildLegisTitle(billInfo),
            }
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                trackedLegislation: firebase.firestore.FieldValue.arrayRemove(billToRemove)
            });
            const filtered = trackedLegislation.filter(item => item.id != billId);
            setTrackedLegislation(filtered)
            setIsEnabled(false)
        }
    }

    const buildSponsorList = (arr) => {
        if (arr?.length > 0) {
            const sponsorArr = [];
            let emailSponsorsStr = ''
            for (let i = 0; i < arr.length; i += 1) {
                emailSponsorsStr += `${arr[i].name}- ${arr[i].district} \n`
                sponsorArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle} key={arr[i].id}>{arr[i].name}- {arr[i].district}</Text>
                    </View>
                )
            }
            setEmailSponsors(emailSponsorsStr)
            setSponsors(sponsorArr)
        }
    }

    const buildCommitteesList = (arr) => {
        if (arr?.length > 0) {
            const committeeArr = [];
            let emailCommitteesStr = ''
            for (let i = 0; i < arr.length; i += 1) {
                emailCommitteesStr += `${arr[i].name} (${chamberObj[arr[i].chamber]}) \n`
                committeeArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle} key={arr[i].id}>{arr[i].name} ({chamberObj[arr[i].chamber]})</Text>
                    </View>
                )
            }
            setEmailCommittees(emailCommitteesStr)
            setCommittees(committeeArr)
        }
    }

    const buildBillHistoryList = (arr) => {
        if (arr?.length > 0) {
            const historyArr = [];
            let emailBillHistoryStr = '';
            for (let i = 0; i < arr.length; i += 1) {
                if (arr[i].type === "Meeting") {
                    emailBillHistoryStr += `--${arr[i].type}
                    ${arr[i].committees}
                    ${arr[i].date?.substring(0, arr[i].date.indexOf('T'))}
                    `
                    historyArr.push(
                        <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                            <Text style={styles.textStyle}>{arr[i].type}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].committees}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].date?.substring(0, arr[i].date.indexOf('T'))}</Text>
                        </View>
                    )
                } else {
                    emailBillHistoryStr += `--${arr[i].type}
                    ${arr[i].name}
                    ${arr[i].date?.substring(0, arr[i].date.indexOf('T'))}
                    `
                    historyArr.push(
                        <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                            <Text style={styles.textStyle}>{arr[i].type}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].name}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].date.substring(0, arr[i].date.indexOf('T'))}</Text>
                        </View>
                    )
                }
            }
            setEmailBillHistory(emailBillHistoryStr)
            setBillHistory(historyArr)
        }
    }

    const buildStatusHistoryList = (arr) => {
        if (arr?.length > 0) {
            const historyArr = [];
            let statusHistoryStr = ''
            for (let i = 0; i < arr.length; i += 1) {
                statusHistoryStr += `${arr[i].date}: ${arr[i].name} \n`
                historyArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle}>{arr[i].date}</Text>
                        <Text style={styles.subTextStyle}>---{arr[i].name}</Text>
                    </View>
                )
            }
            setEmailStatusHistory(statusHistoryStr)
            setStatusHistory(historyArr)
        }
    }

    const buildFootnotesList = (arr) => {
        if (arr?.length > 0 && arr[0] !== '') {
            const footnotesArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                footnotesArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle}>{arr[i]}</Text>
                    </View>
                )
            }
            setFootnotes(footnotesArr)
        }
    }

    const buildVotesList = (arr) => {
        if (arr?.length > 0) {
            const votesArr = [];
            const billUrl = `https://www.legis.ga.gov/legislation/${billId}`
            let emailVotesStr = ''
            for (let i = 0; i < arr.length; i += 1) {
                // yea, nay, notVoting, excused
                emailVotesStr += `--${arr[i].date.substring(0, arr[i].date.indexOf('T'))}: ${arr[i].name} 
                -Yeah: ${arr[i].yea}
                -Nay: ${arr[i].nay}
                -NV: ${arr[i].notVoting}
                -Excused: ${arr[i].excused} \n
                `
                votesArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9 }} key={i}>
                        <Text style={[styles.subTextStyle, { marginLeft: 10, marginBottom: 0, marginTop: 4 }]}>{arr[i].date.substring(0, arr[i].date.indexOf('T'))}</Text>
                        <View style={[styles.voteRow]}>
                            <Pressable
                                onPress={() => navigation.navigate('VotePage', { title: arr[i].name, uri: billUrl, voteId: arr[i].id, options: { headerBackTitleVisible: false } })}
                            >
                                <Text style={[styles.subTextStyle, { marginRight: 15, textDecorationLine: 'underline' }]}>{arr[i].name}:</Text>
                            </Pressable>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Yea</Text>
                                <Text style={styles.subTextStyle}>{arr[i].yea}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Nay</Text>
                                <Text style={styles.subTextStyle}>{arr[i].nay}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>NV</Text>
                                <Text style={styles.subTextStyle}>{arr[i].notVoting}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Excused</Text>
                                <Text style={styles.subTextStyle}>{arr[i].excused}</Text>
                            </View>
                        </View>
                    </View>
                )
            }
            setEmailVotes(emailVotesStr)
            setVotes(votesArr)
        }
    }

    const handleSaveNotes = async () => {
        if (value !== '') {
            const objIndex = userData?.legislationNotes?.findIndex(e => e.id === billId)
            if (objIndex !== -1 && objIndex !== undefined) {
                userData.legislationNotes[objIndex].notes = value;
                const userRef = doc(db, "users", email);
                await updateDoc(userRef, {
                    legislationNotes: userData.legislationNotes,
                });
            } else {
                if (!userData.legislationNotes) {
                    userData.legislationNotes = [{
                        id: billId,
                        notes: value
                    }]
                } else {
                    userData.legislationNotes.push({
                        id: billId,
                        notes: value
                    })
                }
                const userRef = doc(db, "users", email);
                await updateDoc(userRef, {
                    legislationNotes: userData.legislationNotes,
                });
            }
        }
    }

    useEffect(() => {
        async function checkAvailability() {
            const isMailAvailable = await MailComposer.isAvailableAsync();
            setIsAvailable(isMailAvailable);
        }

        checkAvailability();

        async function getBillData() {
            setPageLoading(true)
            // call to database for specific bill
            const trackedArr = [];
            const docRef = doc(db, "users", email);
            await getDoc(docRef)
                .then(doc => doc.data())
                .then(data => {
                    setUserData(data)
                    const legisNotesIndex = data?.legislationNotes?.findIndex(e => e.id === billId)
                    if (legisNotesIndex !== -1 && legisNotesIndex !== undefined) {
                        setValue(data.legislationNotes[legisNotesIndex].notes)
                    }
                    for (let i = 0; i < data.trackedLegislation.length; i += 1) {
                        trackedArr.push(data.trackedLegislation[i])
                    }
                    setTrackedLegislation(trackedArr)
                    return trackedArr
                }).then(async (tracked) => {
                    const docRef = doc(db, "legislation", `${billId}`);
                    await getDoc(docRef)
                        .then(doc => doc.data())
                        .then(data => {
                            const sortedHistory = data.billHistory?.sort(function (a, b) {
                                return new Date(b.date) - new Date(a.date);
                            });
                            if (sortedHistory) {
                                buildBillHistoryList(sortedHistory)
                            }
                            // setBillHistory(sortedHistory)
                            setBillInfo(data)
                            setPdfUrl(`https://www.legis.ga.gov/api/legislation/document/${data.session.library}/${data.versions[0].id}`)
                            buildSponsorList(data.sponsors)
                            buildCommitteesList(data.committees)
                            buildStatusHistoryList(data.statusHistory)
                            buildFootnotesList(data.footnotes.split(';'))
                            buildVotesList(data.votes)

                            if (tracked.some(e => e.id === data.id)) {
                                setIsEnabled(true)
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
            setPageLoading(false)
        }
        getBillData()
    }, [isFocused])

    const goToAnalysis = () => {
        navigation.navigate('AIAnalysis', { title: 'Bill Analysis', email: email, billData: billInfo, options: { headerBackTitleVisible: false } })
    }

    const goToCurrentVersion = () => {
        // const pdfUrl = `https://www.legis.ga.gov/api/legislation/document/${billInfo.session.library}/${billInfo.versions[0].id}`
        navigation.navigate('ExternalView', { title: title, link: pdfUrl, isBill: true, options: { headerBackTitleVisible: false } })
    }
    const openComments = () => {
        setShowComments(!showComments)
    }

    const openHistory = () => {
        if (billInfo.billHistory) {
            setShowBillHistory(!showBillHistory)
            setHistoryEnabled(!historyEnabled)
        }
    }

    const createEmail = () => {
        const body =
            `Notes:\n${value} \n
        First Reader Summary: \n${billInfo?.firstReader} \n
        Current Version: \n${pdfUrl}\n
        Bill History: \n${emailBillHistory}\n
        Sponsors: \n${emailSponsors}\n
        Committees: \n${emailCommittees}\n
        Status History: \n${emailStatusHistory}\n
        Footnotes: \n${billInfo?.footnotes}\n
        Votes: \n${emailVotes}\n`

        MailComposer.composeAsync({
            subject: `${buildLegisTitle(billInfo)} Reporting`,
            body: body,
            recipients: [],
            attachments: []
        });
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    <View style={{ flex: 1 }}>
                        <View style={styles.rowContainer}>
                            <Text style={[styles.textStyle, { marginLeft: 10 }]}>{billInfo?.title}</Text>
                            <Pressable onPress={toggleSwitch} style={{marginRight: 8}}>
                                {isEnabled ?
                                    <Ionicons name="notifications" size={30} color={constants.ICON_COLOR} />
                                    :
                                    <Ionicons name="md-notifications-off-outline" size={30} color={constants.ICON_COLOR} />
                                }
                            </Pressable>
                        </View>
                        <View style={{ flex: 1 }}>
                            <ScrollView style={styles.scrollView}>
                                <View style={[styles.card, styles.buttonClose]}>
                                    <Text style={styles.textStyle}>First Reader Summary:</Text>
                                    <Text style={styles.subTextStyle}>{billInfo?.firstReader}</Text>
                                    <Pressable
                                        onPress={openComments}
                                    >
                                        <Text style={[styles.textStyle, { alignSelf: 'center', color: constants.ICON_COLOR, marginTop: 6 }]}>My Legislation Notes</Text>
                                    </Pressable>
                                    {showComments ? <View>
                                        <View style={styles.notesContainer}>
                                            <TextInput
                                                editable
                                                multiline
                                                numberOfLines={15}
                                                onChangeText={setValue}
                                                value={value}
                                                style={styles.notesStyle}
                                            />
                                        </View>
                                        <View style={styles.buttonRow}>
                                            <Pressable onPress={handleSaveNotes}>
                                                <Text style={[styles.textStyle, { color: constants.ICON_COLOR, marginTop: 8, marginRight: 15 }]}>Save Notes</Text>
                                            </Pressable>
                                            {isAvailable ?
                                                <Pressable onPress={createEmail}>
                                                    <Text style={[styles.textStyle, { color: constants.ICON_COLOR, marginTop: 8 }]}>Email Info</Text>
                                                </Pressable>
                                                : <Text style={[styles.textStyle, { color: '#878787', marginTop: 8 }]}>Email not available</Text>}
                                        </View>
                                    </View> : null}
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Pressable
                                        disabled={userData.tier === '2' ? true : false}
                                        style={[styles.card, styles.buttonClose, { flexDirection: 'row', opacity: userData.tier === '3' ? 1 : .5 }]}
                                        onPress={goToAnalysis}
                                    >
                                        {userData.tier === '2' ?
                                            <Fontisto name="locked" size={20} color={constants.ICON_COLOR} style={{ marginRight: 4 }} />
                                            :
                                            <MaterialCommunityIcons name="book-search-outline" size={24} color={constants.ICON_COLOR} />

                                        }
                                        {/* <MaterialCommunityIcons name="book-search-outline" size={24} color={constants.ICON_COLOR} /> */}
                                        <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Bill Analysis</Text>
                                        <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right', display: userData.tier === '2' ? 'none' : 'block' }} />
                                    </Pressable>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Pressable
                                        style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                        onPress={goToCurrentVersion}
                                    >
                                        <AntDesign name="filetext1" size={24} color={constants.ICON_COLOR} />
                                        <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Current Version</Text>
                                        <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                    </Pressable>
                                </View>
                                {billHistory ?
                                    <View >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowBillHistory(!showBillHistory)}
                                            >
                                                <MaterialCommunityIcons name="history" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Bill History</Text>
                                                {showBillHistory ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showBillHistory ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {billHistory}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {sponsors ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowSponsors(!showSponsors)}
                                            >
                                                <AntDesign name="team" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Sponsors</Text>
                                                {showSponsors ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showSponsors ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {sponsors}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {committees ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowCommittees(!showCommittees)}
                                            >
                                                <Ionicons name="clipboard-outline" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Committees</Text>
                                                {showCommittees ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showCommittees ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {committees}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {statusHistory ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowStatusHistory(!showStatusHistory)}
                                            >
                                                <AntDesign name="check" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Status History</Text>
                                                {showStatusHistory ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showStatusHistory ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {statusHistory}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {footnotes ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowFootnotes(!showFootnotes)}
                                            >
                                                <MaterialIcons name="notes" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Footnotes</Text>
                                                {showFootnotes ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showFootnotes ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {footnotes}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {votes ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowVotes(!showVotes)}
                                            >
                                                <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Votes</Text>
                                                {showVotes ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showVotes ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {votes}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                            </ScrollView>
                        </View>
                    </View>
                }
            </View>
        </View>
    )
}

export default Bill

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        // marginTop: 90,
        // flexGrow: 'grow',
        // maxHeight: '90%',
        flex: 1
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Optional: to vertically center the elements
        padding: 10, // Optional: add some padding if needed
        width: "100%",
    },
    voteRow: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "95%",
        padding: 10
    },
    trackedButton: {
        width: 110,
        padding: 7,
        borderRadius: 20,
    },
    card: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        // alignSelf: 'center',
        width: "100%",
        flexGrow: 1,
        marginBottom: 1,
        // borderBottomWidth: .5,
        // borderColor: 'gray',
    },
    button: {
        flexDirection: 'row',
        width: "95%",
        height: 85,
        borderBottomColor: 'gray',
        borderBottomWidth: .2,
        // marginBottom: 35,
        textAlignVertical: 'center',
        alignItems: 'center',
        paddingTop: 20
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    notesStyle: {
        padding: 10,
        color: constants.TITLE_COLOR,
        fontFamily: 'Verdana'
    },
    notesContainer: {
        backgroundColor: '#5C5858',
        borderColor: constants.SUBTEXT_COLOR,
        opacity: .7,
        borderWidth: 1,
        borderRadius: 9,
        margin: 10,
        height: 230,
    },
    scrollView: {
        // flexGrow: 'grow',
        // marginBottom: 110,
        marginLeft: 10
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 16,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 6,
        fontSize: 13,
        fontWeight: "bold",
        // ...Platform.select({
        //     ios: {
        //         lineHeight: 15
        //     },
        //     android: {}
        // })
    },
})

