import React, { useEffect, useState } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, Pressable, ScrollView, Platform } from 'react-native'
import { AntDesign } from '@expo/vector-icons';
import { Dropdown } from 'react-native-element-dropdown';
import { SearchBar } from 'react-native-elements';
import { useIsFocused } from '@react-navigation/native';
import {
    getStatAgs,
    getTrackedStatAgs,
    fillTable,
    getPastStatAgs,
    getCurrentStatAgs,
} from './statAgsHelper'

const dateOptions = [
    { label: 'Current Events', value: '1' },
    { label: 'Past Events', value: '2' },
];

const StateAgenciesTable = ({ route, navigation }) => {
    const { title, email } = route.params;
    const [tableData, setTableData] = useState();
    const [allData, setAllData] = useState();
    const [dateValue, setDateValue] = useState('1');
    const [isDateFocus, setIsDateFocus] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const isFocused = useIsFocused();
    const headerHeight = useHeaderHeight();

    const handleTracking = async () => {
        setPageLoading(true)
        const trackedstatAgs = await getStatAgs('statAgEvents');
        const filteredstatAgs = await getTrackedStatAgs(email, trackedstatAgs);
        setAllData(filteredstatAgs);
        setPageLoading(false)
    }

    useEffect(() => {
        handleTracking()
    }, [isFocused]);

    useEffect(() => {
        if (allData) {
            let filteredData = allData;
    
            if (searchQuery) {
                filteredData = allData.filter(item => {
                    const hasMatchingStatAg = item.statAgs && item.statAgs.some(statAg =>
                        statAg.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                    const hasMatchingSubject = item.subject && item.subject.toLowerCase().includes(searchQuery.toLowerCase());
                    return hasMatchingStatAg || hasMatchingSubject;
                });
            }
    
            if (dateValue === '1') {
                const current = getCurrentStatAgs(filteredData);
                setTableData(fillTable(current, navigation, email));
            } else if (dateValue === '2') {
                const past = getPastStatAgs(filteredData);
                setTableData(fillTable(past, navigation, email));
            }
        }
    }, [dateValue, allData, searchQuery]);    

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={styles.buttonRow}>
                    <Dropdown
                        style={[styles.dropdown, isDateFocus && { borderColor: 'blue' }]}
                        placeholderStyle={styles.dropdownTextStyle}
                        selectedTextStyle={styles.dropdownTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={dateOptions}
                        // search
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isDateFocus ? 'Current Events' : '...'}
                        searchPlaceholder="Search..."
                        value={dateValue}
                        renderLeftIcon={() => (
                            <AntDesign style={styles.icon} color={constants.ICON_COLOR} name="calendar" size={20} />
                        )}
                        onFocus={() => setIsDateFocus(true)}
                        onBlur={() => setIsDateFocus(false)}
                        onChange={item => {
                            setDateValue(item.value);
                            setIsDateFocus(false);
                        }}
                    />
                </View>
                <SearchBar
                    placeholder="Search Meetings"
                    onChangeText={(text) => setSearchQuery(text)}
                    value={searchQuery}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                    inputStyle={{ color: 'white' }}
                />
                <ScrollView style={styles.scrollView}>
                    {pageLoading ?
                        <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                        :
                        tableData
                    }
                </ScrollView>
            </View>
        </View>
    )
}

export default StateAgenciesTable

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        fontFamily: 'Verdana',
        flex: 1
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        width: 220,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 9,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    dropdownTextStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    icon: {
        marginRight: 7,
    },
    scrollView: {
        // marginBottom: 100
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
        fontFamily: 'Verdana',
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
        fontFamily: 'Verdana',
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        width: "95%",
        height: 25,
        marginLeft: 7,
        fontSize: 15,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 15
            },
            android: {}
        })
    },
})
