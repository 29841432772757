import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, updateDoc } from "firebase/firestore";
import constants from '../../utils/constants';
import { StyleSheet, Text, View, Pressable, Platform } from 'react-native'
import { EvilIcons, Ionicons, AntDesign, MaterialIcons } from '@expo/vector-icons';

const weekdayArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

const getEvents = async (type) => {
    const docRef = collection(db, `${type}`);
    const querySnapshot = await getDocs(docRef)
    const eventData = await querySnapshot.docs.map((doc) => {
        const docEvent = doc.data()

        // Convert UTC to local time
        const startTimeUTC = docEvent.start ? new Date(docEvent.start) : new Date("2030-01-01T09:00:41Z");
        const localDate = startTimeUTC.toLocaleDateString();
        const localTime = startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        return {
            subject: docEvent.subject,
            noDate: docEvent.noDate ? docEvent.noDate : docEvent.start ? false : true,
            uponAdjourn: docEvent.uponAdjourn ? docEvent.uponAdjourn : false,
            adjournText: docEvent.adjournText ? docEvent.adjournText : null,
            start: docEvent.start ? docEvent.start : null,
            date: localDate,
            time: localTime,
            id: docEvent.id,
            location: docEvent.location,
            chamber: docEvent.chamber,
            billsResolutions: docEvent.billsResolutions,
            committees: docEvent.committees,
        };
    });
    return eventData
}

const getTrackedEvents = async (email, events) => {
    const docRef = doc(db, "users", email);
    return await getDoc(docRef)
        .then(doc => doc.data())
        .then(async (data) => {
            const wordsObjArr = [];
            const billsObjArr = [];
            const committeesObjArr = [];
            if (data.trackedWords) {
                for (let i = 0; i < data.trackedWords.length; i += 1) {
                    const eventArr = events.filter(x => x.subject.toLowerCase().includes(data.trackedWords[i].toLowerCase()));
                    if (eventArr.length > 0) {
                        wordsObjArr.push(...eventArr)
                    }
                }
            }
            if (data.trackedLegislation) {
                for (let i = 0; i < data.trackedLegislation.length; i += 1) {
                    const eventArr = events.filter(x => x.billsResolutions?.includes(data.trackedLegislation[i].legislationTitle));
                    if (eventArr.length > 0) {
                        billsObjArr.push(...eventArr)
                    }
                }
            }
            if (data.trackedCommitteeNames) {
                for (let i = 0; i < data.trackedCommitteeNames.length; i += 1) {
                    const eventArr = events.filter(x => x.committees?.includes(data.trackedCommitteeNames[i]));
                    if (eventArr.length > 0) {
                        committeesObjArr.push(...eventArr)
                    }
                }
            }

            const result = Array.from([...wordsObjArr, ...billsObjArr, ...committeesObjArr]
                .reduce((m, o) => m.set(o.id, o), new Map)
                .values()
            );

            const allTrackedArr = result.map(o => o.id);
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                allTrackedEvents: allTrackedArr
            });
            return result
        })
}

const sortEventsByChamber = (events) => {
    const houseArr = [];
    const senateArr = [];
    if (events) {
        for (let i = 0; i < events.length; i += 1) {
            if (events[i].chamber === 1) {
                houseArr.push(events[i]);
            } else if (events[i].chamber === 2) {
                senateArr.push(events[i])
            }
        }
    }
    return {
        houseArr,
        senateArr,
    }
}

const fillTable = (eventData, navigation, email, page) => {
    const dataArr = [];
    if (Array.isArray(eventData)) {
        for (let i = 0; i < eventData.length; i += 1) {
            let dateTimeString;
            let noStart = false;
            if (eventData[i].noDate && eventData[i].start == null) {
                dateTimeString = new Date("2030-08-21T13:32:06Z")
                noStart = true
            } else {
                dateTimeString = new Date(eventData[i].start);
            }
            const nameArr = [];
            if (eventData[i].committees) {
                for (let j = 0; j < eventData[i].committees.length; j += 1) {
                    nameArr.push(
                        <Text key={j} style={styles.textStyle}>{eventData[i].committees[j]}</Text>
                    )
                }
            }
            const weekday = weekdayArr[dateTimeString.getDay()];
            const month = monthNameArr[dateTimeString.getMonth()];
            const dateNum = dateTimeString.getDate();
            let formattedTime = eventData[i].time;
            const isNoDate = eventData[i].noDate ? eventData[i].noDate : false;
            const isUponAdjourn = eventData[i].uponAdjourn ? eventData[i].uponAdjourn : false;
            let time;
            if (isNoDate) {
                formattedTime = "(TBD)";
            }
            if (isUponAdjourn) {
                formattedTime = eventData[i].adjournText ? `${eventData[i].adjournText}` : "(TBD)";
            }
            const eventObj =
                <Pressable
                    key={i}
                    style={[styles.button, styles.buttonClose]}
                    onPress={() => navigation.navigate('Meeting', { title: "Meeting Info", id: eventData[i].id, email: email, page: page, options: { headerBackTitleVisible: false } })}
                >
                    <View style={styles.rowContainer}>
                        <View style={[styles.calendarContainer]}>
                            <View style={styles.calendarMonth}>
                                <Text style={styles.monthText}>{noStart ? "No" : month}</Text>
                            </View>
                            <View style={[styles.calendarDate]}>
                                <Text style={styles.weekdayText}>{noStart ? null : weekday}</Text>
                                <Text style={[styles.dateText, { fontSize: noStart ? 11.5 : 16, marginTop: noStart ? 10 : 0 }]}>{noStart ? 'Date' : dateNum}</Text>
                            </View>
                        </View>
                        <View style={{ width: '98%' }}>
                            {page === 'trackedEvents' ?
                                nameArr
                                :
                                <Text style={styles.textStyle}>{eventData[i].subject}</Text>
                            }
                            <View style={styles.rowContainer}>
                                <MaterialIcons name="subject" size={20} color="#e5e5e5" style={{ marginTop: 3, marginBottom: 0, marginRight: 3, marginLeft: 3 }} />
                                <Text style={{ ...styles.subjectText }}>{eventData[i].subject}</Text>
                            </View>
                            <View style={styles.rowContainer}>
                                <EvilIcons name="location" size={22} color="white" style={{ marginTop: 3, marginBottom: 4, marginRight: 2 }} />
                                <Text style={{ ...styles.locationText }}>{eventData[i].location}</Text>
                            </View>
                            {isNoDate ?
                                null
                                :
                                <View style={styles.rowContainer}>
                                    <Ionicons name="time-outline" size={17} color="white" style={{ marginTop: -1.5, marginLeft: 4, marginRight: 3 }} />
                                    <Text style={{ ...styles.subTextStyle, marginTop: 0 }}>{formattedTime}</Text>
                                </View>
                            }
                        </View>
                    </View>
                </Pressable>
            dataArr.push(eventObj)
        }
        if (dataArr.length > 0) {
            return dataArr
        } else {
            return (
                <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                    <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                    <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
                </View>
            )
        }
    } else {
        return (
            <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
            </View>
        )
    }
}

const getPastEvents = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate < Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(b.start) - new Date(a.start);
    });
}

const getCurrentEvents = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate >= Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
    });
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        marginTop: 20,
    },
    dropdown: {
        backgroundColor: '#838383',
        height: 40,
        width: 170,
        borderColor: constants.ICON_COLOR,
        borderWidth: 0.5,
        borderRadius: 8,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    scrollView: {
        marginBottom: 100
    },
    button: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarContainer: {
        backgroundColor: 'white',
        width: 48.5,
        borderColor: 'black',
        borderWidth: 2.65,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 7,
        marginTop: 4,
        ...Platform.select({
            ios: {
                height: 51.5,
            },
            android: {
                height: 53.5,
            },
            web: {
                height: 53.5,
            }
        })
    },
    calendarMonth: {
        backgroundColor: '#D21F3C',
        height: 18,
        borderColor: 'gray',
        borderWidth: .09,
        borderBottomColor: 'black',
        borderBottomWidth: 1.6,
        borderTopRightRadius: 2.5,
        borderTopLeftRadius: 2.5,
        alignItems: 'center',
    },
    calendarDate: {
        alignItems: 'center',
    },
    monthText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    weekdayText: {
        color: "#373737",
        marginBottom: -4,
        fontSize: 11,
        fontWeight: "bold",
    },
    dateText: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 16,
    },
    subjectText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 0,
        fontSize: 12,
        fontWeight: "bold",
    },
    locationText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 6,
        fontSize: 11,
        fontWeight: "bold",
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 14,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 0,
        fontSize: 11,
        fontWeight: "bold",
    },
    noEntriesText: {
        color: 'white',
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlign: 'center',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    rowContainer: {
        flexDirection: 'row',
        marginBottom: 2,
        flexGrow: 1,
        width: "90%",
    },
    icon: {
        color: 'white',
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})


export {
    getEvents,
    getTrackedEvents,
    fillTable,
    getPastEvents,
    getCurrentEvents,
    sortEventsByChamber,
}