import React, { useState, useEffect } from 'react'
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, Button, Alert, Pressable, Platform } from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";

const StatAgTracking = ({ route }) => {
    const { email } = route.params;
    const [trackedStatAgs, setTrackedStatAgs] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [agencies, setAgencies] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        async function getStatAgs() {
            const docRef = doc(db, 'stateAgencies', 'statAgs');
            await getDoc(docRef)
                .then(doc => doc.data())
                .then(data => {
                    const agencyArr = [];
                    for (let i = 0; i < data.agencies.length; i += 1) {
                        agencyArr.push(data.agencies[i].name)
                    }
                    setAgencies(agencyArr)
                })
            getTrackedAgs();
        }
        getStatAgs()
    }, []);

    const getTrackedAgs = async () => {
        const docRef = doc(db, "users", email);
        await getDoc(docRef)
            .then(doc => doc.data())
            .then(data => {
                const trackedArr = [];
                for (let i = 0; i < data.trackedStatAgs.length; i += 1) {
                    trackedArr.push(data.trackedStatAgs[i])
                }
                setTrackedStatAgs(trackedArr)
                setPageLoading(false)
            })
    }

    const handleTrack = async (item) => {
        // Optimistically update UI
        const newTrackedStatAgs = [...trackedStatAgs, item];
        setTrackedStatAgs(newTrackedStatAgs);

        try {
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                trackedStatAgs: newTrackedStatAgs
            });
        } catch (error) {
            // Revert state if update fails
            setTrackedStatAgs(trackedStatAgs.filter(e => e !== item));
        }
    }

    const handleSearch = (text) => {
        const formattedText = text.toLowerCase();
        setSearch(text);
        if (formattedText.length > 1) {
            const searchArr = agencies.filter(a => a.toLowerCase().includes(formattedText));
            setData(searchArr);
        } else {
            setData([]);
        }
    }

    const handleDeleteStatAg = async (item) => {
        // Optimistically update UI
        const newTrackedStatAgs = trackedStatAgs.filter(e => e !== item);
        setTrackedStatAgs(newTrackedStatAgs);

        try {
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                trackedStatAgs: newTrackedStatAgs
            });
        } catch (error) {
            // Revert state if update fails
            setTrackedStatAgs([...trackedStatAgs, item]);
        }
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <SearchBar
                    placeholder="Search State Agencies to track"
                    onChangeText={(text) => handleSearch(text)}
                    value={search}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                />
                {(data.length > 0)
                    ? <FlatList
                        style={styles.scrollView}
                        data={data}
                        renderItem={({ item }) => (
                            <View
                                style={[styles.button, styles.buttonClose]}
                            >
                                <View style={styles.rowContainer}>
                                    <View style={{ flex: 5, justifyContent: 'center' }}>
                                        <Text style={styles.textStyle}>{item}</Text>
                                    </View>
                                    <View style={{ flex: .5, justifyContent: 'center' }}>
                                        {trackedStatAgs.includes(item) ?
                                            <Pressable
                                                onPress={() => handleDeleteStatAg(item)}
                                            >
                                                <MaterialIcons name="cancel" size={24} color="gray" />
                                            </Pressable>
                                            :
                                            <Pressable
                                                onPress={() => handleTrack(item)}
                                            >
                                                <AntDesign name="pluscircle" size={24} color={constants.ICON_COLOR} />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                        )}
                    />
                    : null
                }
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    (trackedStatAgs.length > 0 && data.length === 0) ?
                        <View style={{flex: 1}}>
                            <View style={{ margin: 15 }}>
                                <Text style={styles.textStyle}>Tracked State Agencies</Text>
                            </View>
                            <FlatList
                                style={styles.scrollView}
                                data={trackedStatAgs}
                                renderItem={({ item }) => (
                                    <View style={[styles.button, styles.buttonClose]}>
                                        <View style={styles.rowContainer}>
                                            <View style={{ flex: 5, justifyContent: 'center' }}>
                                                <Text style={[styles.textStyle, { flex: 1, flexWrap: 'wrap' }]}>{item}</Text>
                                            </View>
                                            <View style={{ flex: .5, justifyContent: 'center' }}>
                                                <Pressable
                                                    onPress={() => handleDeleteStatAg(item)}
                                                >
                                                    <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            />
                        </View>
                        : null
                }
            </View>
        </View>
    )
}

export default StatAgTracking

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        // marginTop: 95,
        height: '100%',
        flex: 1
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    scrollView: {
        marginBottom: 20,
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        width: "90%",
        marginLeft: 7,
        marginBottom: 2,
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})
