import React, { useState, useEffect } from 'react';
import { StyleSheet, ActivityIndicator, Text, View, Button, TextInput, ScrollView, FlatList, Pressable, SafeAreaView, Platform } from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { AntDesign, Fontisto, MaterialCommunityIcons, MaterialIcons, Ionicons } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";

function VotePage({ route, navigation }) {
  const { uri, voteId } = route.params;
  const [showYea, setShowYea] = useState(false);
  const [showNay, setShowNay] = useState(false);
  const [showNV, setShowNV] = useState(false);
  const [showExcused, setShowExcused] = useState(false);
  const [yea, setYea] = useState([]);
  const [nay, setNay] = useState([]);
  const [nv, setNv] = useState([]);
  const [excused, setExcused] = useState([]);
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const docRef = doc(db, "votes", `${voteId}`);
    getDoc(docRef)
      .then(doc => doc.data())
      .then(data => {
        const yeaData = [];
        const nayData = [];
        const excData = [];
        const nvData = [];
        const voteArr = data.votes;
        const chamber = data.name.includes('House') ? 'house' : 'senate';
        for (let i = 0; i < voteArr.length; i += 1) {
          const memberObj = {
            chamber: chamber,
            memberId: voteArr[i].member.id
          }
          const memberElement = (
            <View style={{ backgroundColor: constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
              <Pressable style={styles.subTextStyle} key={voteArr[i].member.id} onPress={() => navigation.navigate('ExternalView', { title: voteArr[i].member.name, member: memberObj })}>
                <Text style={[styles.textStyle, { textDecorationLine: 'underline' }]}>{voteArr[i].member.name}</Text>
              </Pressable>
            </View>
          )
          if (voteArr[i].memberVoted === 0) {
            yeaData.push(memberElement)
          } else if (voteArr[i].memberVoted === 1) {
            nayData.push(memberElement)
          } else if (voteArr[i].memberVoted === 2) {
            excData.push(memberElement)
          } else if (voteArr[i].memberVoted === 3) {
            nvData.push(memberElement)
          }
        }
        setYea(yeaData);
        setNay(nayData);
        setExcused(excData);
        setNv(nvData);
      })
      .catch(error => {
        console.error(error);
      });

  }, [])


  return (
    <View style={[styles.container, { marginTop: headerHeight + 10, height: '100%' }]}>
      <ScrollView style={styles.scrollView}>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Pressable
              style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
              onPress={() => setShowYea(!showYea)}
            >
              <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
              <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Yea ({yea.length})</Text>
              {showYea ?
                <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                :
                <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
              }
            </Pressable>
          </View>
          {showYea ?
            <View style={[styles.card, styles.buttonClose, { marginTop: -5, width: 'auto' }]}>
              {yea}
            </View>
            :
            null
          }
        </View>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Pressable
              style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
              onPress={() => setShowNay(!showNay)}
            >
              <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
              <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Nay ({nay.length})</Text>
              {showNay ?
                <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                :
                <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
              }
            </Pressable>
          </View>
          {showNay ?
            <View style={[styles.card, styles.buttonClose, { marginTop: -5, width: 'auto' }]}>
              {nay}
            </View>
            :
            null
          }
        </View>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Pressable
              style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
              onPress={() => setShowNV(!showNV)}
            >
              <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
              <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Not Voting ({nv.length})</Text>
              {showNV ?
                <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                :
                <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
              }
            </Pressable>
          </View>
          {showNV ?
            <View style={[styles.card, styles.buttonClose, { marginTop: -5, width: 'auto' }]}>
              {nv}
            </View>
            :
            null
          }
        </View>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Pressable
              style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
              onPress={() => setShowExcused(!showExcused)}
            >
              <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
              <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Excused ({excused.length})</Text>
              {showExcused ?
                <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                :
                <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                // <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
              }
            </Pressable>
          </View>
          {showExcused ?
            <View style={[styles.card, styles.buttonClose, { marginTop: -5, width: 'auto' }]}>
              {excused}
            </View>
            :
            null
          }
        </View>
      </ScrollView>
    </View>
  )
}

export default VotePage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: constants.PRIMARY_DARK,
    // marginTop: 90,
    // flexGrow: 'grow',
    // maxHeight: '90%',
    flex: 1
  },
  rowContainer: {
    // marginTop: 10,
    // marginBottom: 10,
    flexDirection: 'row',
    flexGrow: 0,
    width: "95%",
    padding: 10
  },
  voteRow: {
    flexDirection: 'row',
    flexGrow: 1,
    width: "95%",
    padding: 10
  },
  trackedButton: {
    width: 110,
    padding: 7,
    borderRadius: 20,
  },
  card: {
    borderRadius: 9,
    borderWidth: 3,
    borderColor: 'black',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 12,
    elevation: 2,
    // alignSelf: 'center',
    width: "98%",
    flexGrow: 1,
    marginBottom: 1,
    // borderBottomWidth: .5,
    // borderColor: 'gray',
  },
  button: {
    flexDirection: 'row',
    width: "95%",
    height: 85,
    borderBottomColor: 'gray',
    borderBottomWidth: .2,
    // marginBottom: 35,
    textAlignVertical: 'center',
    alignItems: 'center',
    paddingTop: 20
  },
  buttonClose: {
    backgroundColor: constants.SECONDARY_DARK,
  },
  notesStyle: {
    padding: 10,
    color: constants.TITLE_COLOR,
    fontFamily: 'Verdana'
  },
  notesContainer: {
    backgroundColor: '#5C5858',
    borderColor: constants.SUBTEXT_COLOR,
    opacity: .7,
    borderWidth: 1,
    borderRadius: 9,
    margin: 10,
    height: 230,
  },
  scrollView: {
    flexGrow: 'grow',
    // marginBottom: 110,
    marginLeft: 10,
    marginBottom: 30
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textStyle: {
    color: constants.TITLE_COLOR,
    marginBottom: 2,
    marginLeft: 2,
    fontSize: 16,
    fontWeight: "bold",
    textAlignVertical: "center",
    ...Platform.select({
      ios: {
        lineHeight: 20
      },
      android: {}
    })
  },
  subTextStyle: {
    color: constants.SUBTEXT_COLOR,
    marginBottom: 6,
    fontSize: 13,
    fontWeight: "bold",
    // ...Platform.select({
    //     ios: {
    //         lineHeight: 15
    //     },
    //     android: {}
    // })
  },
})


