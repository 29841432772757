import React, { useEffect, useRef, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.min.mjs';
import constants from '../../utils/constants';

GlobalWorkerOptions.workerSrc = workerSrc;

const PDFViewer = ({ pdfUrl, onLinkClick }) => {
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const renderPDF = async () => {
            try {
                const loadingTask = getDocument(pdfUrl);
                const pdf = await loadingTask.promise;

                const container = containerRef.current;
                container.innerHTML = ''; // Clear previous content

                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 1 });
                    const scale = container.clientWidth / viewport.width;

                    const scaledViewport = page.getViewport({ scale });

                    const canvas = document.createElement('canvas');
                    container.appendChild(canvas);

                    const context = canvas.getContext('2d');
                    canvas.height = scaledViewport.height;
                    canvas.width = scaledViewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: scaledViewport,
                    };

                    const renderTask = page.render(renderContext);
                    await renderTask.promise;

                    const annotations = await page.getAnnotations();

                    // Add event listener to intercept link clicks
                    canvas.addEventListener('click', (event) => {
                        const rect = canvas.getBoundingClientRect();
                        const x = (event.clientX - rect.left) * (viewport.width / canvas.width);
                        const y = (event.clientY - rect.top) * (viewport.height / canvas.height);

                        annotations.forEach((annotation) => {
                            if (annotation.subtype === 'Link' && annotation.url) {
                                const [minX, minY, maxX, maxY] = [
                                    Math.min(annotation.rect[0], annotation.rect[2]),
                                    Math.min(annotation.rect[1], annotation.rect[3]),
                                    Math.max(annotation.rect[0], annotation.rect[2]),
                                    Math.max(annotation.rect[1], annotation.rect[3]),
                                ];

                                // Adjust the y-coordinate to match the PDF coordinate system
                                const adjustedY = viewport.height - y;

                                if (x >= minX && x <= maxX && adjustedY >= minY && adjustedY <= maxY) {
                                    onLinkClick(annotation.url);
                                }
                            }
                        });
                    });
                }
            } catch (error) {
                console.error('Error rendering PDF:', error);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        renderPDF();
    }, [pdfUrl, onLinkClick]);

    return (
        <View ref={containerRef} style={{ width: '80%', height: '100%', overflow: 'auto', position: 'relative' }}>
            <ActivityIndicator size="large" color={constants.ICON_COLOR} style={{ display: loading ? 'flex' : 'none', marginTop: 200 }} />
            <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'auto' }}></div>
        </View>
    );
};

export default PDFViewer;