import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from 'firebase/compat/app';
import {
  initializeAuth,
  getReactNativePersistence
} from 'firebase/auth/react-native';
import 'firebase/compat/firestore';
import {initializeFirestore} from 'firebase/firestore';

const config = {
    apiKey: "AIzaSyBWksazx97Tdv4BxdagQdbKiwVohbZlE-A",
    authDomain: "clarity-356512.firebaseapp.com",
    projectId: "clarity-356512",
    storageBucket: "clarity-356512.appspot.com",
    messagingSenderId: "65545189617",
    appId: "1:65545189617:web:3f5d82b6559be8046e9150",
    measurementId: "G-49LKCHLP38"
  };
  // const config = {
  //   apiKey: process.env.REACT_APP_API_KEY,
  //   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  //   projectId: process.env.REACT_APP_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_APP_ID,
  //   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  // };

const app = firebase.initializeApp(config)

const auth = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage)
});

// const db = firebase.firestore();
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export { db, auth };