import React, { useState } from 'react';
import { Modal, View, Text, StyleSheet, TouchableOpacity, ScrollView, TouchableWithoutFeedback } from 'react-native';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import constants from '../../utils/constants';

const CalendarButtonModal = ({ calendar, goToLegislation }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {calendar && (
        <TouchableOpacity
          style={styles.floatingButton}
          onPress={() => setModalVisible(true)}
        >
          <AntDesign name="calendar" size={24} color={constants.ICON_COLOR} />
          <Text style={styles.buttonText}>Calendar Bills/Resolutions</Text>
        </TouchableOpacity>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPressOut={() => setModalVisible(false)}
        >
          <TouchableWithoutFeedback>
            <View style={styles.modalView}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => setModalVisible(false)}
              >
                <Ionicons name="close" size={24} color={constants.ICON_COLOR} />
              </TouchableOpacity>
              <Text style={styles.modalText}>Bills and Resolutions</Text>
              <ScrollView contentContainerStyle={styles.scrollContainer}>
                {calendar && calendar.billsResolutions && calendar.billsResolutions.map((bill, index) => (
                  <TouchableOpacity
                    key={index}
                    style={styles.billButton}
                    onPress={() => {
                      goToLegislation(bill);
                      setModalVisible(false);
                    }}
                  >
                    <Text style={styles.billText}>{bill}</Text>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  floatingButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: constants.SECONDARY_DARK,
    borderRadius: 20,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1000,
  },
  buttonText: {
    color: 'white',
    marginLeft: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalView: {
    margin: 20,
    backgroundColor: constants.PRIMARY_DARK,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    width: '25%',
    maxHeight: '80%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: constants.TITLE_COLOR,
  },
  scrollContainer: {
    alignItems: 'center',
  },
  billButton: {
    padding: 10,
    marginVertical: 5,
    backgroundColor: constants.SECONDARY_DARK,
    borderRadius: 10,
    width: '100%',
    alignItems: 'center',
  },
  billText: {
    fontSize: 16,
    color: constants.TITLE_COLOR,
  },
});

export default CalendarButtonModal;